import * as B from '@blueprintjs/core';
import {History} from 'history';
import * as I from 'immutable';
import React, {FormEvent} from 'react';

import AppNav from 'app/components/AppNav';
import {NewPortfolioLayersSelector} from 'app/components/Library/PortfolioLayersSelector';
import GeometryFileBehaviorCallout from 'app/components/ManagePortfolio/GeometryFileBehvaiorCallout';
import {ApiFeature} from 'app/modules/Remote/Feature';
import {
  ApiFeatureCollection,
  HydratedFeatureCollection,
} from 'app/modules/Remote/FeatureCollection';
import {
  ApiOrganization,
  ApiOrganizationUser,
  getMeasurementSystem,
} from 'app/modules/Remote/Organization';
import {LoggedInUserActions} from 'app/providers/AuthProvider';
import * as CONSTANTS from 'app/utils/constants';
import * as conversionUtils from 'app/utils/conversionUtils';
import * as featureCollectionUtils from 'app/utils/featureCollectionUtils';
import * as featureUtils from 'app/utils/featureUtils';

import ManagePropertiesFileUploader from './ManageFeaturesFileUploader';
import ManageFeaturesMap from './ManageFeaturesMap';
import {ManageFeaturesActions, ManageFeaturesState} from './ManageFeaturesProvider';
import ManageFeaturesTable from './ManageFeaturesTable';
import {DrawPropertiesState} from './ManagePropertiesDraw';
import cs from './styles.styl';
import {MapInteractionProvider} from '../MonitorProjectView/Map';

import {ManagePropertiesMode} from '.';

// Ok for a lot of these fields to be blank because this ApiFeatureCollection is only used for display purposes
// in the ManageProperties components
export const BLANK_API_FEATURE_COLLECTION: ApiFeatureCollection = {
  id: 1,
  organizationId: '00000000-0000-0000-0000-000000000000',
  type: 'FeatureCollection',
  name: 'Example Property',
  product: 'lens',
  createdAt: '2019-03-20T19:13:31.643263+00:00',
  updatedAt: '2019-03-20T19:13:31.643263+00:00',
  processingEndDate: null,
  processingStartDate: null,
  isArchived: false,
  bounds: {
    coordinates: [
      [
        [-122.13426941555338, 34.303227191563536],
        [-122.13426941555338, 40.423925695110974],
        [-118.87179824683717, 40.423925695110974],
        [-118.87179824683717, 34.303227191563536],
        [-122.13426941555338, 34.303227191563536],
      ],
    ],
    type: 'Polygon',
  },
  staticBounds: null,
  tiles: null,
  schema: {
    fields: {},
    geoType: 'POLYGON',
  },
  views: [],
  status: null,
  processingConfig: {
    enrolledLayers: {},
    processingMetadata: {},
  },
  supportedLayersForProduct: {},
  processingLayersBySource: [],
};

const BLANK_FEATURE_COLLECTION: Omit<HydratedFeatureCollection, 'features'> = I.fromJS(
  BLANK_API_FEATURE_COLLECTION
);

export const BLANK_HYDRATED_FEATURE_COLLECTION: HydratedFeatureCollection =
  BLANK_FEATURE_COLLECTION.set('features', I.List());

export interface Props {
  history: History;
  organization: I.ImmutableOf<ApiOrganization>;
  profile: I.ImmutableOf<ApiOrganizationUser>;
  loggedInUserActions: LoggedInUserActions;
  manageFeaturesState: ManageFeaturesState;
  manageFeaturesActions: ManageFeaturesActions;
  loading: boolean;
  mode: ManagePropertiesMode;
  firebaseToken: string | null;
  //drawPropertiesState is always used in draw mode and always undefined in other modes
  drawPropertiesState?: DrawPropertiesState;
  //overlayNames is always used in overlays mode and always undefined in other modes
  overlayNames?: string[];
}

const ManageFeaturesView: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  history,
  organization,
  profile,
  loggedInUserActions,
  firebaseToken,
  manageFeaturesState: {
    filename,
    uploadedFeatureCollection,
    isConvertingFile,
    problems,
    nameField,
    nameValues,
    partNameField,
    selectedFeature,
    groupProperties,
    isUploading,
    selectedFeatureIds,
    areaUnit,
    featureCollectionErrors,
    allUploadableFeatureIds,
    existingFeatureCollection,
    portfolioName,
    warningsMap,
    partNameValues,
    selectedLayerKeys,
    selectedOverlayIds,
  },
  manageFeaturesActions: {
    setFilename,
    setUploadedFeatureCollection,
    setFilePath,
    setNameField,
    setNameValues,
    setPartNameField,
    setSelectedFeature,
    convertFile,
    uploadFeatures,
    uploadDrawnFeatures,
    setGroupProperties,
    setSelectedFeatureIds,
    setProblems,
    setFeatureCollectionErrors,
    setPortfolioName,
    setPartNameValues,
    setSelectedLayerKeys,
    setSelectedOverlayIds,
  },
  mode,
  drawPropertiesState,
  loading,
  overlayNames,
}) => {
  const warningsCount = React.useMemo(
    () =>
      Object.keys(warningsMap).reduce(
        (warningsCount, id) => warningsCount + (warningsMap[id]?.length || 0),
        0
      ),
    [warningsMap]
  );

  // We keep 2 featureCollections in this component.
  // uploadedFeatureCollection is a geojson.FeatureCollection that is returned from the API
  // upon conversion of the user's file. This only changes if the user uploads a different
  // file. This is typed as a NumberedFeatureCollection to reduce casting when doing lots of
  // look up and comparing operations across these featureCollections.
  // mapFeatureCollection is derived from that and is the HydratedFeatureCollection that we use
  // to display on the map. This gets updated with the name fields the user selected, if they select
  // or unselect properties etc.
  const mapFeatureCollection: HydratedFeatureCollection | null = React.useMemo(() => {
    if (!uploadedFeatureCollection) {
      return null;
    }
    const features: I.List<I.ImmutableOf<ApiFeature>> = I.List([
      ...uploadedFeatureCollection.features.flatMap((feature) => {
        const featureId = feature.id;
        // If a feature in the file is not selected, don't include it in the featureCollection
        if (!selectedFeatureIds.has(featureId) || !feature.properties) {
          return [];
        }

        let mapFeature: I.ImmutableOf<ApiFeature> = I.fromJS({
          ...feature,
          id: featureId,
        });

        if (nameValues) {
          mapFeature = mapFeature.setIn(['properties', 'name'], nameValues.get(featureId));
        }

        const partName = partNameValues?.get(featureId);
        if (partName) {
          mapFeature = mapFeature.setIn(['properties', 'multiFeaturePartName'], partName);
        }
        return [mapFeature];
      }),
    ]);
    let blankWithFeatures = BLANK_FEATURE_COLLECTION.set('features', features);

    if (mode === 'overlay') {
      blankWithFeatures = blankWithFeatures.set('name', portfolioName || '');
    }
    return blankWithFeatures;
  }, [
    mode,
    nameValues,
    portfolioName,
    partNameValues,
    selectedFeatureIds,
    uploadedFeatureCollection,
  ]);

  const [propertyCount, locationCount, mapFeatureCollectionArea] = React.useMemo(() => {
    if (!mapFeatureCollection) {
      return [0, 0, 0];
    }

    const propertyNames = mapFeatureCollection
      .get('features')
      .map((f) => f?.getIn(['properties', 'name']));

    const propertyCount = I.Set(propertyNames).size;

    const locationCount = mapFeatureCollection.get('features').size;

    const totalArea = featureCollectionUtils.featureCollectionArea(mapFeatureCollection, areaUnit);

    return [propertyCount, locationCount, totalArea];
  }, [mapFeatureCollection, areaUnit]);

  const existingFeatureCollectionArea = React.useMemo(() => {
    return existingFeatureCollection
      ? featureCollectionUtils.featureCollectionArea(existingFeatureCollection, areaUnit)
      : 0;
  }, [existingFeatureCollection, areaUnit]);

  // Check if property additions will cause portfolio to exceed max size of 3 million acres
  const exceededPortfolioSize = React.useMemo(
    () =>
      existingFeatureCollectionArea + mapFeatureCollectionArea >
      conversionUtils.convert(3000000, CONSTANTS.UNIT_AREA_ACRE, areaUnit),
    [existingFeatureCollectionArea, mapFeatureCollectionArea, areaUnit]
  );

  const [isSelectLayersModalOpen, setIsSelectLayersModalOpen] = React.useState(false);

  // Here we make sure that new users go through available datasets
  const [datasetsHaveBeenReviewed, setDatasetsHaveBeenReviewed] =
    React.useState(!!existingFeatureCollection);

  //these next few consts take care of much of the logic to control what is hidden/shown based on mode
  const needsPartName = () => {
    switch (mode) {
      case 'overlay':
        return false; // we don't care if overlay features have duplicate names
      case 'draw':
      case 'parcel':
      case 'upload':
        return !!(
          nameField &&
          uploadedFeatureCollection &&
          (groupProperties === 'individual' || locationCount !== propertyCount || !!warningsCount)
        );
      default:
        return false;
    }
  };

  const modeValidator = (mode: ManagePropertiesMode) => {
    if (!['draw', 'parcel', 'upload', 'overlay'].includes(mode)) return false;
    if (mode === 'overlay') return !duplicateOverlayNameWarning;
    else return nameField && (!needsPartName() || partNameField) && !exceededPortfolioSize;
  };

  const readyToSubmit = (): boolean =>
    [
      !!uploadedFeatureCollection,
      selectedFeatureIds.size > 0,
      existingFeatureCollection || !!portfolioName,
      mode === 'overlay' || !!(existingFeatureCollection || datasetsHaveBeenReviewed),
      modeValidator(mode),
    ].every((condition) => condition);

  const showFileUploader = mode === 'upload' || mode === 'overlay';

  const duplicateOverlayNameWarning =
    mode === 'overlay' && portfolioName && overlayNames?.includes(portfolioName);

  // This sort relies on the nameField selected, not the actual nameValues to avoid properties jumping
  // around and resorting while editing names
  const sortedFeatures = React.useMemo(() => {
    if (!nameField || !uploadedFeatureCollection) {
      return [];
    }

    // Only sort features from a file. For draw mode, this would cause features
    // to resort themselves as you type since we are directly modifying the
    // uploadedFeatureCollection
    return mode === 'upload' || mode === 'overlay'
      ? uploadedFeatureCollection.features.sort((a, b) => {
          const aField: string | number = a!.properties![nameField] ?? '';
          const bField: string | number = b!.properties![nameField] ?? '';
          return featureUtils.alphanumericSort(aField, bField);
        })
      : uploadedFeatureCollection.features;
  }, [uploadedFeatureCollection, nameField, mode]);

  const newFeatures = mapFeatureCollection?.get('features') || I.List([]);
  const existingFeatures = existingFeatureCollection?.get('features') || I.List([]);

  // We can be confident these ids wont overlap because the new features
  // returned from the convertFile endpoint always start at 1 through
  // the number of features while the exising features are high up into the 9
  // digit range now so it's very unlikely there will be overlap.
  const allFeatures: I.ImmutableOf<ApiFeature[]> = I.List(newFeatures.concat(existingFeatures));

  const measurementSystem = getMeasurementSystem(organization);

  const getDisabledTooltipText = (): string | undefined => {
    const nameMissingMessage = `Please name your ${mode === 'overlay' ? 'overlay' : 'portfolio'}`;
    const datasetsNeedReviewMessage = 'Please review datasets';
    const datasetsNeedReviewAndNameMessage = `${nameMissingMessage} and review datasets`;
    const datasetsNeedReview =
      mode !== 'overlay' && !existingFeatureCollection && !datasetsHaveBeenReviewed;

    if (!portfolioName && datasetsNeedReview) {
      return datasetsNeedReviewAndNameMessage;
    }

    if (!portfolioName) {
      return nameMissingMessage;
    }

    if (datasetsNeedReview) {
      return datasetsNeedReviewMessage;
    }
  };

  return (
    <div>
      <AppNav
        history={history}
        organization={organization}
        profile={profile}
        selectedProject={null}
        loggedInUserActions={loggedInUserActions}
      />
      {/* We are only using the hover interactions provided by the MapInteractionProvider.
        The select interactions could be used in the future to scroll to an entry in the table when
        a feature is clicked on. Note that there is another prop used in this file called selectedFeatureIds that contains
        all the featureIds to upload */}
      <MapInteractionProvider
        features={allFeatures}
        selectedFeatureIds={I.Set([])}
        setSelectedFeatureIds={() => null}
      >
        {({hoveredFeatureIds, hoverFeatureById}) => {
          return (
            <div className={cs.content}>
              {loading && <B.Spinner />}
              {!loading && (
                <div className={cs.form}>
                  <div className={cs.formBody}>
                    <h2 className={cs.header}>
                      {existingFeatureCollection ? (
                        `Add Properties to ${portfolioName}`
                      ) : (
                        <B.InputGroup
                          type="text"
                          large
                          className={cs.nameInput}
                          value={portfolioName || undefined}
                          placeholder={`Name your ${mode === 'overlay' ? 'overlay' : 'portfolio'}…`}
                          onChange={(event: FormEvent<HTMLInputElement>) => {
                            const element = event.currentTarget as HTMLInputElement;
                            const {value} = element;
                            setPortfolioName(value);
                          }}
                        />
                      )}
                      {mode === 'draw' && drawPropertiesState && (
                        <B.ButtonGroup className={cs.drawingModeButtons}>
                          <B.Tooltip
                            content={'Click here to begin drawing a property'}
                            disabled={drawPropertiesState.editMode}
                            intent={'primary'}
                          >
                            <B.Button
                              minimal={drawPropertiesState.editMode}
                              active={drawPropertiesState.editMode}
                              text={'Draw'}
                              intent={'primary'}
                              onClick={() => {
                                drawPropertiesState.setFeatureToEdit(null);
                                drawPropertiesState.setEditMode((editMode) => !editMode);
                              }}
                            />
                          </B.Tooltip>
                          {drawPropertiesState.editMode && !!drawPropertiesState.setDrawingMode && (
                            <B.ButtonGroup>
                              <B.Button
                                icon="polygon-filter"
                                onClick={() => drawPropertiesState.setDrawingMode!('draw_polygon')}
                                active={
                                  drawPropertiesState.editMode &&
                                  drawPropertiesState.drawingMode === 'draw_polygon'
                                }
                              ></B.Button>
                              <B.Button
                                icon="widget"
                                onClick={() =>
                                  drawPropertiesState.setDrawingMode!('draw_rectangle')
                                }
                                active={
                                  drawPropertiesState.editMode &&
                                  drawPropertiesState.drawingMode === 'draw_rectangle'
                                }
                              ></B.Button>
                            </B.ButtonGroup>
                          )}
                        </B.ButtonGroup>
                      )}
                    </h2>
                    <div className={cs.formContent}>
                      {showFileUploader && (
                        <ManagePropertiesFileUploader
                          needsPartName={needsPartName()}
                          isConvertingFile={isConvertingFile}
                          problems={problems}
                          filename={filename}
                          onFilenameChange={(file) => {
                            // Setting the file name before validating the file type so that
                            // the user can see the file name with the error, if applicable.
                            setFilename(file.name);
                            setUploadedFeatureCollection(null);
                            setProblems(null);
                            setFeatureCollectionErrors(null);
                            setFilePath(null);
                            setNameField(null);
                            setPartNameField(null);
                            convertFile(file, mode);
                          }}
                          uploadedFeatureCollection={uploadedFeatureCollection}
                          nameField={nameField}
                          partNameField={partNameField}
                          setNameField={setNameField}
                          setPartNameField={setPartNameField}
                          mode={mode}
                        />
                      )}
                      {nameValues && (
                        <div className={cs.reviewProperties}>
                          {needsPartName() && (
                            <GeometryFileBehaviorCallout
                              groupProperties={groupProperties}
                              setGroupProperties={setGroupProperties}
                              mode={mode}
                            />
                          )}
                          {duplicateOverlayNameWarning && (
                            <B.Callout className={cs.callout} intent={B.Intent.DANGER}>
                              You already have an overlay named {portfolioName}. Choose another name
                              to save your overlay.
                            </B.Callout>
                          )}
                          {!!sortedFeatures.length && (
                            <ManageFeaturesTable
                              selectedFeatureIds={selectedFeatureIds}
                              allUploadableFeatureIds={allUploadableFeatureIds}
                              setSelectedFeatureIds={setSelectedFeatureIds}
                              needsPartName={needsPartName()}
                              areaUnit={areaUnit}
                              groupProperties={groupProperties}
                              nameValues={nameValues}
                              nameField={nameField}
                              setNameValues={setNameValues}
                              partNameValues={partNameValues}
                              setPartNameValues={setPartNameValues}
                              warningsMap={warningsMap}
                              sortedFeatures={sortedFeatures}
                              hoverFeatureById={hoverFeatureById}
                              hoveredFeatureIds={hoveredFeatureIds}
                              setSelectedFeature={setSelectedFeature}
                              setUploadedFeatureCollection={setUploadedFeatureCollection}
                              mode={mode}
                              drawPropertiesState={drawPropertiesState}
                            />
                          )}
                          {warningsCount > 0 && (
                            <B.Callout
                              className={cs.callout}
                              intent={B.Intent.WARNING}
                              icon={'warning-sign'}
                            >
                              Please review the {warningsCount}{' '}
                              {warningsCount > 1 ? 'warnings' : 'warning'} in the table above before
                              proceeding.
                            </B.Callout>
                          )}
                          {exceededPortfolioSize && (
                            <B.Callout className={cs.callout} intent={B.Intent.DANGER}>
                              These property additions will cause the portfolio to exceed the max
                              size of 3 million acres. Please remove some selected properties and
                              try again or upload them to a another portfolio.
                            </B.Callout>
                          )}
                          {featureCollectionErrors && (
                            <B.Callout className={cs.callout} intent={B.Intent.DANGER} icon={null}>
                              <ul>
                                {featureCollectionErrors.map((p, x) => (
                                  <li key={x}>{p}</li>
                                ))}
                              </ul>
                            </B.Callout>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {/**in overlays mode, you can upload even if no nameValues,
                  as long as you have a file */}
                  {(nameValues || (mode === 'overlay' && uploadedFeatureCollection)) && (
                    <div className={cs.uploadFooter}>
                      <B.Callout
                        intent={readyToSubmit() ? B.Intent.SUCCESS : B.Intent.NONE}
                        icon={null}
                        style={{marginBottom: '1rem'}}
                      >
                        <div>
                          {(mode === 'upload' || mode === 'draw' || mode === 'parcel') && (
                            <>
                              {propertyCount} {propertyCount === 1 ? 'property' : 'properties'}{' '}
                              across {locationCount}{' '}
                              {locationCount === 1 ? 'location' : 'locations'} with a total of{' '}
                              {conversionUtils.numberWithCommas(
                                mapFeatureCollectionArea.toString()
                              )}{' '}
                              {areaUnit === 'areaHectare' ? 'hectares' : 'acres'} will be added to{' '}
                              {portfolioName ? (
                                <span className={cs.bold}>{portfolioName}</span>
                              ) : (
                                'your Lens Portfolio.'
                              )}
                            </>
                          )}
                          {mode === 'overlay' && (
                            <>
                              Upload{' '}
                              {portfolioName ? (
                                <span className={cs.bold}>{portfolioName}</span>
                              ) : (
                                'your overlay'
                              )}
                            </>
                          )}
                        </div>
                      </B.Callout>
                      <B.ButtonGroup>
                        {mapFeatureCollection &&
                          !existingFeatureCollection &&
                          mode !== 'overlay' && (
                            <B.Tooltip
                              content={'Choose which datasets to process in your portfolio'}
                              disabled={datasetsHaveBeenReviewed}
                            >
                              <B.AnchorButton
                                className={cs.uploadButton}
                                style={{borderRadius: '3px'}}
                                intent={B.Intent.SUCCESS}
                                text="Review datasets"
                                onClick={() => {
                                  setDatasetsHaveBeenReviewed(true);
                                  setIsSelectLayersModalOpen(true);
                                }}
                              />
                            </B.Tooltip>
                          )}

                        <B.Tooltip
                          disabled={readyToSubmit()}
                          content={getDisabledTooltipText()}
                          intent={'danger'}
                        >
                          <B.AnchorButton
                            className={cs.uploadButton}
                            style={{borderRadius: '3px'}}
                            text="Confirm"
                            intent={readyToSubmit() ? B.Intent.SUCCESS : B.Intent.NONE}
                            disabled={!readyToSubmit()}
                            onClick={() =>
                              mode === 'draw' || mode === 'parcel'
                                ? uploadDrawnFeatures()
                                : uploadFeatures()
                            }
                            loading={isUploading}
                          />
                        </B.Tooltip>
                      </B.ButtonGroup>
                    </div>
                  )}
                </div>
              )}
              {!loading && (
                <ManageFeaturesMap
                  existingFeatureCollection={existingFeatureCollection}
                  newFeatureCollection={mapFeatureCollection}
                  hoverFeatureById={hoverFeatureById}
                  hoveredFeatureIds={hoveredFeatureIds}
                  selectedFeature={selectedFeature}
                  measurementSystem={measurementSystem}
                  mode={mode}
                  firebaseToken={firebaseToken}
                  {...((mode === 'draw' || mode === 'parcel') && drawPropertiesState
                    ? {
                        allowDrawing: true,
                        isEditMode: drawPropertiesState.editMode,
                        setIsEditMode: drawPropertiesState.setEditMode,
                        featureToEdit: drawPropertiesState.featureToEdit,
                        setFeatureToEdit: drawPropertiesState.setFeatureToEdit,
                        onMapFeatureClick: drawPropertiesState.onMapFeatureClick,
                        drawingMode: drawPropertiesState.drawingMode,
                      }
                    : {allowDrawing: false})}
                />
              )}
              {!loading && !existingFeatureCollection && mapFeatureCollection && (
                <NewPortfolioLayersSelector
                  isOpen={isSelectLayersModalOpen}
                  onClose={() => setIsSelectLayersModalOpen(false)}
                  selectedLayerKeys={selectedLayerKeys}
                  setSelectedLayerKeys={setSelectedLayerKeys}
                  selectedOverlayIds={selectedOverlayIds}
                  setSelectedOverlayIds={setSelectedOverlayIds}
                />
              )}
            </div>
          );
        }}
      </MapInteractionProvider>
    </div>
  );
};

export default ManageFeaturesView;
