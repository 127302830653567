import * as B from '@blueprintjs/core';
import {History} from 'history';
import * as I from 'immutable';
import React from 'react';
import {useLocation} from 'react-router-dom';

import LayersIcon from 'app/components/Library/Icons/LayersIcon';
import {useProjects} from 'app/providers/ProjectsProvider';
import featureFlags, {useUserInfo} from 'app/utils/featureFlags';
import {alphanumericSort} from 'app/utils/featureUtils';
import {getOrgPrefix} from 'app/utils/organizationUtils';
import {makeUuidPrefix} from 'app/utils/routeUtils';
import {shouldTreatAsParentOrg} from 'app/utils/userUtils';

import {useNavSidebarContext} from './NavSidebarProvider';
import * as cs from './styles.styl';

interface NavItem {
  title: string;
  icon: B.IconName | JSX.Element | null;
  urlSlug: string;
  /* subItems are not allowed to have subItems for now */
  subItems?: Omit<NavItem, 'subItems'>[];
  /* some settings items aren't relevant for parent orgs
   * because they are not allowed to have their own portfolios */
  disableForParentOrg?: boolean;
}

const NAV_ITEMS: NavItem[] = [
  {
    urlSlug: 'projects',
    title: 'Home',
    icon: 'home',
  },
  {
    urlSlug: 'portfolios-fake-url',
    title: 'Portfolios',
    icon: 'folder-open',
  },
  {
    urlSlug: 'settings/layers',
    title: 'Library',
    icon: <LayersIcon />,
    disableForParentOrg: true,
  },
  {
    urlSlug: 'dashboard',
    title: 'Dashboard',
    icon: 'control',
  },
  {
    urlSlug: 'settings',
    title: 'Settings',
    icon: 'cog',
    subItems: [
      {urlSlug: 'settings/organization', title: 'Organization', icon: 'office'},
      {urlSlug: 'settings/team', title: 'Team', icon: 'person'},
      {urlSlug: 'settings/billing', title: 'Billing and Contracts', icon: 'dollar'},
      {
        urlSlug: 'settings/notifications',
        title: 'Notifications',
        icon: 'notifications',
        disableForParentOrg: true,
      },
      {
        urlSlug: 'settings/overlays',
        title: 'Overlay Uploads',
        icon: 'new-layers',
        disableForParentOrg: true,
      },
    ],
  },
];

export const NavSidebarContainer: React.FunctionComponent<
  React.PropsWithChildren<{history: History}>
> = ({children, history}) => {
  return (
    <div className={cs.container}>
      <NavSidebar history={history} />
      {children}
    </div>
  );
};

const NavSidebar: React.FunctionComponent<{history: History}> = ({history}) => {
  const [currentOrganization, profile, profileOrganizations] = useUserInfo();
  const {pathname: currentUrlPath} = useLocation();
  const {expandedItems, setExpandedItems} = useNavSidebarContext();

  const [projectsMap, , , {loading: isProjectsLoading}] = useProjects();
  const sortedProjects = React.useMemo(
    () =>
      (projectsMap || I.OrderedMap())
        .valueSeq()
        .toList()
        .sortBy((project) => project!.get('name'), alphanumericSort)
        .toList(),
    [projectsMap]
  );

  // We disable some settings pages for parent orgs because they are not relevant
  // for them since they are not allowed to have their own portfolios.
  const treatAsParentOrg = shouldTreatAsParentOrg(
    profile,
    currentOrganization,
    profileOrganizations
  );

  const navItemsWithPortfolios = React.useMemo(() => {
    const filteredNavItems = NAV_ITEMS.filter((item) =>
      item.urlSlug === 'dashboard' ? featureFlags.DASHBOARD(currentOrganization, profile) : true
    );
    const portfolios = sortedProjects
      .map(
        (project) =>
          ({
            title: project!.get('name'),
            icon: null,
            urlSlug: `projects/${makeUuidPrefix(project!.get('id'))}`,
          }) as NavItem
      )
      .toArray();
    return filteredNavItems.map((item) =>
      item.urlSlug === 'portfolios-fake-url' ? {...item, subItems: portfolios} : item
    );
  }, [currentOrganization, profile, sortedProjects]);

  return (
    <div className={cs.sidebar}>
      <B.Menu>
        {navItemsWithPortfolios.map(({title, icon, urlSlug, subItems}) => {
          const navItemPath = `/${getOrgPrefix(currentOrganization!)}/${urlSlug}`;
          const isActivePage = currentUrlPath === navItemPath;
          const isExpanded = expandedItems.includes(urlSlug);
          const isLoading = urlSlug === 'portfolios-fake-url' && isProjectsLoading;

          return (
            <React.Fragment key={urlSlug}>
              <B.MenuItem
                multiline
                icon={icon}
                text={title}
                active={isActivePage}
                onClick={() => {
                  // If you click on an item, open it's children if they have any
                  if (subItems) {
                    setExpandedItems((prev) =>
                      isExpanded ? prev.filter((item) => item !== urlSlug) : [...prev, urlSlug]
                    );
                  } else {
                    history.push(navItemPath);
                  }
                }}
                labelElement={
                  subItems && (
                    <B.AnchorButton
                      className={cs.expandButton}
                      minimal
                      small
                      loading={isLoading}
                      icon={isExpanded ? 'chevron-down' : 'chevron-right'}
                    />
                  )
                }
              />
              {subItems && (
                <B.Collapse isOpen={isExpanded}>
                  <B.Menu className={cs.submenu}>
                    {subItems.map(
                      ({title: subTitle, icon: subIcon, urlSlug: subSlug, disableForParentOrg}) => {
                        const subPath = `/${getOrgPrefix(currentOrganization!)}/${subSlug}`;
                        const isSubActive = currentUrlPath === subPath;

                        return (
                          <B.MenuItem
                            multiline
                            key={subSlug}
                            icon={subIcon}
                            text={subTitle}
                            active={isSubActive}
                            disabled={treatAsParentOrg && disableForParentOrg}
                            onClick={() => history.push(subPath)}
                          />
                        );
                      }
                    )}
                  </B.Menu>
                </B.Collapse>
              )}
            </React.Fragment>
          );
        })}
      </B.Menu>
    </div>
  );
};
