import React from 'react';

interface NavContextType {
  expandedItems: string[];
  setExpandedItems: React.Dispatch<React.SetStateAction<string[]>>;
}

const NavContext = React.createContext<NavContextType | undefined>(undefined);

// Track what navigation items are expanded in the sidebar
export const NavSidebarProvider: React.FC<React.PropsWithChildren> = ({children}) => {
  const [expandedItems, setExpandedItems] = React.useState<string[]>([]);

  return (
    <NavContext.Provider value={{expandedItems, setExpandedItems}}>{children}</NavContext.Provider>
  );
};

export const useNavSidebarContext = () => {
  const context = React.useContext(NavContext);
  if (!context) {
    throw new Error('useNavContext must be used within a NavSidebarProvider');
  }
  return context;
};
