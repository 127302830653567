import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import {isEqual} from 'lodash';
import React from 'react';
import {BooleanParam, StringParam, useQueryParams} from 'use-query-params';

import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {ApiProject} from 'app/modules/Remote/Project';
import {recordEvent} from 'app/tools/Analytics';
import {orgIsAllowedTier} from 'app/utils/organizationUtils';

import {LibraryItemCard, layerRequestCard} from './card';
import {LibraryCheckoutModal} from './checkout';
import {
  DEFAULT_LAYER_FILTER_STATE,
  LibraryFilterControls,
  LibraryFilterState,
  filterLibraryDatasets,
} from './filters';
import {LayersLibraryMode} from './LensLibraryProvider';
import cs from './LensLibraryView.styl';
import {FEATURED_LAYER_KEYS, SWIM_LANES} from './libraryConfig';
import {LayerDataset, LibraryDataset, LibraryDatasetType, OverlayDataset} from './LibraryTypes';
import {LibraryItemDetailsModal} from './modal';
import {FEATURED_OVERLAY_METADATA} from './overlaysConfig';
import {useOverlays} from './OverlaysProvider';
import {isLayerAdded, makeLayerActionButton, makeOverlayActionButton} from './portfolioMenu';
import {LibrarySplashCarousel} from './splash';
import {SETTINGS_MODE_BREAKPOINTS, Swimlane} from './swimlane';
import * as GlobalSettings from '../GlobalSettings/GlobalSettings';

const filterDatasetsBySwimlane = (
  swimlaneKeys: string[],
  datasets: LibraryDataset<LayerDataset | OverlayDataset>[]
) => {
  const swimlaneDatasets: LibraryDataset<LayerDataset | OverlayDataset>[] = [];

  // we want to be able to maintain the sort order from the swimlane, hence pushing to an empty
  // list rather than just filtering the existing datasets
  swimlaneKeys.forEach((swimlaneKey) => {
    const swimlaneDataset = datasets.find((libLayer) => libLayer.libraryKey == swimlaneKey);
    if (swimlaneDataset) {
      swimlaneDatasets.push(swimlaneDataset);
    }
  });
  return swimlaneDatasets;
};

const LensLibraryView: React.FunctionComponent<
  React.PropsWithChildren<{
    layers: LibraryDataset<LayerDataset>[];
    projects: I.ImmutableOf<ApiProject[]>;
    isLoading: boolean;
    fcIdToProjectMap: Record<number, ApiProject>;
    addLayers: (projectIds: string[], layerKey: string) => Promise<void>;
    removeLayers: (projectIds: string[], layerKey: string) => Promise<void>;
    subscribedPremiumSourceIds: string[];
    organization: I.ImmutableOf<ApiOrganization>;
    profile: I.ImmutableOf<ApiOrganizationUser>;
    purchasePaidLayer: (
      sourceId: string,
      billingInterval: string,
      subscriptionProrationDate: number,
      billingCycleAnchor: number,
      couponId: string | undefined
    ) => Promise<string>;
    role: ApiOrganizationUser['role'];
    mode: LayersLibraryMode;
    selectedOverlayIds?: number[];
    setSelectedOverlayIds?: React.Dispatch<React.SetStateAction<number[]>>;
    onClose?: () => void;
  }>
> = ({
  isLoading,
  layers,
  projects,
  fcIdToProjectMap,
  addLayers,
  removeLayers,
  selectedOverlayIds,
  setSelectedOverlayIds,
  purchasePaidLayer,
  subscribedPremiumSourceIds,
  organization,
  profile,
  role,
  mode,
  onClose,
}) => {
  const organizationId = organization.get('id');
  const [libraryFilterState, setLibraryFilterState] = React.useState<LibraryFilterState>(
    DEFAULT_LAYER_FILTER_STATE
  );

  const [query, setQuery] = useQueryParams({
    detailsOpen: BooleanParam,
    checkoutOpen: BooleanParam,
    currentDataset: StringParam,
  });

  const setQueryPushIn = (newQuery) => setQuery(newQuery, 'pushIn');

  const onOpenDetailsModal = (layerKey) => {
    setQueryPushIn({
      currentDataset: layerKey,
      detailsOpen: true,
    });
  };

  const onCloseDetailsModal = () => {
    setQueryPushIn({detailsOpen: undefined, currentDataset: undefined});
  };

  const onOpenCheckoutModal = (layerKey) => {
    setQueryPushIn({
      currentDataset: layerKey,
      checkoutOpen: true,
    });
  };

  const onCloseCheckoutModal = (extraQueryParamsToClear: string[]) => {
    setQueryPushIn({
      ...extraQueryParamsToClear.reduce((acc, param) => ({...acc, [param]: undefined}), {}),
      checkoutOpen: undefined,
      currentDataset: query.detailsOpen ? query.currentDataset : undefined,
    });
  };

  const clearAllState = (extraQueryParamsToClear: string[] = []) => {
    setQueryPushIn({
      ...extraQueryParamsToClear.reduce((acc, param) => ({...acc, [param]: undefined}), {}),
      detailsOpen: undefined,
      checkoutOpen: undefined,
      currentDataset: undefined,
    });
  };

  const addLibraryItem = (
    projectIds: string[],
    libraryKey: string,
    addTracking: boolean,
    name: string,
    type: LibraryDatasetType
  ): Promise<void> => {
    if (addTracking) {
      recordEvent('Added dataset', {
        name: name,
        libraryKey: libraryKey,
        portfolioCount: projectIds.length,
      });
    }
    if (type === LibraryDatasetType.LAYER) {
      return addLayers(projectIds, libraryKey);
    } else {
      return actions.addOverlays(projectIds, parseInt(libraryKey));
    }
  };

  const removeLibraryItem = (
    projectIds: string[],
    libraryKey: string,
    addTracking: boolean,
    name: string,
    type: LibraryDatasetType
  ): Promise<void> => {
    if (addTracking) {
      recordEvent('Removed dataset', {
        name: name,
        libraryKey: libraryKey,
        portfolioCount: projectIds.length,
      });
    }
    if (type === LibraryDatasetType.LAYER) {
      return removeLayers(projectIds, libraryKey);
    } else {
      return actions.removeOverlays(projectIds, parseInt(libraryKey));
    }
  };

  const {upstreamOverlays, actions} = useOverlays();

  const queriedLayer = layers.find((l) => l.layerKey === query.currentDataset);
  const queriedOverlay = upstreamOverlays.find((o) => o.name === query.currentDataset);
  //there can only be one dataset in our URL at a time.
  const queriedDataset = queriedLayer || queriedOverlay;

  const restrictions: {[featureCollectionId: number]: boolean} = React.useMemo(() => {
    return upstreamOverlays.reduce((accumulator, overlay) => {
      accumulator[overlay.featureCollectionId] = !orgIsAllowedTier(
        organization,
        overlay.allowedTiers
      );
      return accumulator;
    }, {});
  }, [upstreamOverlays, organization]);

  const filteredDatasets = React.useMemo<LibraryDataset<LayerDataset | OverlayDataset>[]>(
    () =>
      filterLibraryDatasets(libraryFilterState, [...layers, ...upstreamOverlays], fcIdToProjectMap),
    [layers, libraryFilterState, organization, profile, upstreamOverlays, fcIdToProjectMap]
  );

  // Select the featured layers, sort them in featured order
  const featuredLayers = filterDatasetsBySwimlane(FEATURED_LAYER_KEYS, filteredDatasets);

  const showExploreView =
    isEqual(libraryFilterState, DEFAULT_LAYER_FILTER_STATE) && mode === 'librarySettings';

  const renderDetailsModal = () => {
    if (queriedDataset) {
      if (queriedDataset.type === LibraryDatasetType.LAYER) {
        const layer = queriedDataset;

        const actionButton = makeLayerActionButton(
          layer,
          fcIdToProjectMap,
          projects,
          addLibraryItem,
          removeLibraryItem,
          onOpenCheckoutModal,
          subscribedPremiumSourceIds,
          mode
        );
        return (
          <LibraryItemDetailsModal
            isOpen={!!query.detailsOpen}
            onClose={onCloseDetailsModal}
            actionButton={actionButton}
            name={layer.name}
            source={layer.source}
            description={layer.description}
            geographicExtent={layer.geographicExtent}
            resolution={layer.resolution}
            thumbnailUrl={layer.thumbnailUrl}
            tags={layer.tags}
            license={layer.license}
            type={LibraryDatasetType.LAYER}
          />
        );
      } else if (queriedDataset.type === LibraryDatasetType.OVERLAY) {
        const overlay = queriedDataset;
        if (overlay) {
          const projectIds = overlay.projectIds;
          const actionButton = makeOverlayActionButton(
            overlay!,
            projectIds,
            mode,
            projects.toJS().map((project) => project.id),
            restrictions,
            addLibraryItem,
            removeLibraryItem,
            projects,
            selectedOverlayIds,
            setSelectedOverlayIds
          );
          return (
            <LibraryItemDetailsModal
              isOpen={!!query.detailsOpen}
              onClose={onCloseDetailsModal}
              actionButton={actionButton}
              name={overlay.name}
              source={overlay.source}
              description={overlay.description}
              geographicExtent={overlay.geographicExtent}
              thumbnailUrl={overlay.thumbnailUrl}
              tags={overlay.tags}
              license={overlay.license}
              type={LibraryDatasetType.OVERLAY}
            />
          );
        }
      }
    }
  };

  return isLoading ? (
    <GlobalSettings.CenteredSpinner />
  ) : (
    <>
      <LibraryFilterControls
        libraryFilterState={libraryFilterState}
        setLibraryFilterState={setLibraryFilterState}
        filteredDatasets={filteredDatasets}
        fcIdToProjectMap={fcIdToProjectMap}
        onClose={mode === 'singlePortfolio' ? onClose : undefined}
        mode={mode}
      />
      {showExploreView && (
        <div className={cs.layersPageContainer}>
          <LibrarySplashCarousel
            featuredItems={featuredLayers}
            openDetails={onOpenDetailsModal}
            header="Recently Added"
          />
          {Object.keys(SWIM_LANES).map((category) => {
            return (
              <LibrarySwimlane
                key={category}
                header={category}
                filteredDatasets={filterDatasetsBySwimlane(
                  SWIM_LANES[category].layers,
                  filteredDatasets
                )}
                fcIdToProjectMap={fcIdToProjectMap}
                projects={projects}
                restrictions={restrictions}
                addLibraryItem={addLibraryItem}
                removeLibraryItem={removeLibraryItem}
                onOpenCheckoutModal={onOpenCheckoutModal}
                subscribedPremiumSourceIds={subscribedPremiumSourceIds}
                onOpenDetailsModal={onOpenDetailsModal}
                mode={mode}
              />
            );
          })}
        </div>
      )}
      {!showExploreView && (
        <div className={cs.filteredCards}>
          {filteredDatasets.map((dataset: LibraryDataset<LayerDataset | OverlayDataset>, i) => {
            if (dataset.type === LibraryDatasetType.OVERLAY) {
              const overlay = dataset;
              const thumbnailUrl = overlay.thumbnailUrl;
              const projectIds = overlay.projectIds;
              const name = overlay.name;
              const subheading = overlay.source;
              const detail = (
                <>
                  <b>Coverage:</b> {overlay.geographicExtent}
                </>
              );
              const added = projectIds.length > 0;
              const actionButton = makeOverlayActionButton(
                overlay,
                projectIds,
                mode,
                projects.toJS().map((project) => project.id),
                restrictions,
                addLibraryItem,
                removeLibraryItem,
                projects,
                selectedOverlayIds,
                setSelectedOverlayIds
              );
              return (
                <LibraryItemCard
                  key={i}
                  heading={name}
                  subheading={subheading}
                  detail={detail}
                  thumbnailUrl={thumbnailUrl}
                  added={added}
                  actionButton={actionButton}
                  setDetailsModalOpen={onOpenDetailsModal}
                  // Using an overlay's name as a layerKey here since they are unique to
                  // allow for sharing of details modal logic with layers
                  layerKey={name}
                  noDetailView={true}
                  type={LibraryDatasetType.OVERLAY}
                />
              );
            } else {
              const layer = dataset;
              const added = isLayerAdded(layer, subscribedPremiumSourceIds);
              const actionButton = makeLayerActionButton(
                layer,
                fcIdToProjectMap,
                projects,
                addLibraryItem,
                removeLibraryItem,
                onOpenCheckoutModal,
                subscribedPremiumSourceIds,
                mode
              );

              const detail = (
                <div className={cs.tags}>
                  {layer.tags.map((t) => (
                    <B.Tag
                      key={t.label}
                      round
                      minimal
                      style={{backgroundColor: t.color, fontWeight: 'normal'}}
                    >
                      {t.label}
                    </B.Tag>
                  ))}
                </div>
              );

              return (
                <LibraryItemCard
                  key={i}
                  added={added}
                  heading={layer.name}
                  subheading={layer.source}
                  resolution={
                    <B.Tag minimal round>
                      {`${layer.resolution}m`}
                    </B.Tag>
                  }
                  type={LibraryDatasetType.LAYER}
                  actionButton={actionButton}
                  thumbnailUrl={layer.thumbnailUrl}
                  layerKey={layer.layerKey}
                  setDetailsModalOpen={onOpenDetailsModal}
                  detail={detail}
                  noDetailView={true}
                />
              );
            }
          })}
          {layerRequestCard()}
        </div>
      )}
      {query.detailsOpen && renderDetailsModal()}
      {query.checkoutOpen && queriedDataset && queriedDataset.type === LibraryDatasetType.LAYER && (
        <LibraryCheckoutModal
          isOpen={query.checkoutOpen}
          closeCheckoutModal={onCloseCheckoutModal}
          closeAllModals={clearAllState}
          layer={queriedDataset}
          isOtherModalOpen={!!query.detailsOpen}
          organizationId={organizationId}
          purchasePaidLayer={purchasePaidLayer}
          role={role}
        />
      )}
    </>
  );
};

const LibrarySwimlane: React.FunctionComponent<
  React.PropsWithChildren<{
    header: string;
    filteredDatasets: LibraryDataset<OverlayDataset | LayerDataset>[];
    fcIdToProjectMap: Record<number, ApiProject>;
    projects: I.ImmutableListOf<ApiProject>;
    restrictions;
    addLibraryItem: (
      projectIds: string[],
      layerKey: string,
      addTracking: boolean,
      name: string,
      type: LibraryDatasetType
    ) => Promise<void>;
    removeLibraryItem: (
      projectIds: string[],
      layerKey: string,
      addTracking: boolean,
      name: string,
      type: LibraryDatasetType
    ) => Promise<void>;
    onOpenCheckoutModal: (layerKey: string) => void;
    subscribedPremiumSourceIds: string[];
    onOpenDetailsModal: (layerKey: string) => void;
    mode: LayersLibraryMode;
  }>
> = ({
  header,
  filteredDatasets,
  fcIdToProjectMap,
  projects,
  restrictions,
  addLibraryItem,
  removeLibraryItem,
  onOpenCheckoutModal,
  subscribedPremiumSourceIds,
  onOpenDetailsModal,
  mode,
}) => {
  return filteredDatasets.length ? (
    <Swimlane
      header={header}
      sliderSettings={{
        responsive: SETTINGS_MODE_BREAKPOINTS,
      }}
    >
      {filteredDatasets.map((dataset: LibraryDataset<OverlayDataset | LayerDataset>, i) => {
        if (dataset.type === LibraryDatasetType.OVERLAY) {
          const overlay = dataset;
          const thumbnailUrl = overlay.thumbnailUrl;
          const projectIds = overlay.projectIds;
          const name = overlay.name;
          const subheading = FEATURED_OVERLAY_METADATA[name]?.source;

          const detail = (
            <>
              <b>Coverage:</b> {FEATURED_OVERLAY_METADATA[name]?.geographicExtent}
              <div className={cs.tags}>
                {overlay.tags.map((t) => (
                  <B.Tag
                    key={t.label}
                    round
                    minimal
                    style={{backgroundColor: t.color, fontWeight: 'normal'}}
                  >
                    {t.label}
                  </B.Tag>
                ))}
              </div>
            </>
          );
          const added = projectIds.length > 0;
          const actionButton = makeOverlayActionButton(
            overlay,
            projectIds,
            mode,
            projects.toJS().map((project) => project.id),
            restrictions,
            addLibraryItem,
            removeLibraryItem,
            projects,
            [],
            undefined
          );
          return (
            <LibraryItemCard
              key={i}
              heading={name}
              subheading={subheading}
              detail={detail}
              thumbnailUrl={thumbnailUrl}
              added={added}
              actionButton={actionButton}
              setDetailsModalOpen={onOpenDetailsModal}
              // Using an overlay's name as a layerKey here since they are unique to
              // allow for sharing of details modal logic with layers
              layerKey={name}
              noDetailView={true}
              type={LibraryDatasetType.OVERLAY}
            />
          );
        }
        const layer = dataset;
        const added = isLayerAdded(layer, subscribedPremiumSourceIds);

        const actionButton = makeLayerActionButton(
          layer,
          fcIdToProjectMap,
          projects,
          addLibraryItem,
          removeLibraryItem,
          onOpenCheckoutModal,
          subscribedPremiumSourceIds,
          mode
        );

        const detail = (
          <div className={cs.tags}>
            {layer.tags.map((t) => (
              <B.Tag
                key={t.label}
                round
                minimal
                style={{backgroundColor: t.color, fontWeight: 'normal'}}
              >
                {t.label}
              </B.Tag>
            ))}
          </div>
        );

        return (
          <LibraryItemCard
            key={layer.name}
            added={added}
            heading={layer.name}
            subheading={layer.source}
            resolution={
              <B.Tag minimal round>
                {`${layer.resolution}m`}
              </B.Tag>
            }
            type={LibraryDatasetType.LAYER}
            actionButton={actionButton}
            thumbnailUrl={layer.thumbnailUrl}
            layerKey={layer.layerKey}
            setDetailsModalOpen={onOpenDetailsModal}
            detail={detail}
            noDetailView={true}
          />
        );
      })}
    </Swimlane>
  ) : null;
};

export default LensLibraryView;
