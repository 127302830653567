import * as B from '@blueprintjs/core';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import * as I from 'immutable';
import React from 'react';

import {api} from 'app/modules/Remote';
import {
  APIAlertPolicyProperties,
  AlertPolicy,
  ApiAlertEnrollment,
  ApiAlertPolicy,
} from 'app/modules/Remote/Feature';
import {AlertTypeProperties, ThresholdAlertProperties} from 'app/modules/Remote/Feature/types';
import {ApiFeatureCollection} from 'app/modules/Remote/FeatureCollection';
import {recordEvent} from 'app/tools/Analytics';
import {camelCaseKeys, snakeCaseKeys} from 'app/utils/apiUtils';
import {CUSTOM_ALERT_TYPE_CATEGORY, CUSTOM_ALERT_TYPE_THRESHOLD} from 'app/utils/constants';
import * as layerUtils from 'app/utils/layerUtils';

import {usePushNotification} from '../Notification';
import {AlertFormState} from './AlertConfigurationModal';

const FAILED_CREATE_ERROR =
  'Error creating alert policy, please make sure all required fields are filled out correctly and that your user role is not read-only';
const FAILED_DELETE_ERROR =
  'Error deleting alert policy, please make sure that your user role is not read-only';

interface AlertPolicyContextValue {
  alertPolicies: AlertPolicy[];
  actions: AlertPolicyActions;
  meta: {isFetching: boolean; error: unknown | null};
}

export const AlertPolicyContext = React.createContext<AlertPolicyContextValue | undefined>(
  undefined
);

interface AlertPolicyActions {
  createAlertPolicy: (
    featureCollection: I.ImmutableOf<ApiFeatureCollection>,
    formState: AlertFormState
  ) => Promise<ApiAlertPolicy>;
  updateAlertPolicy: (
    alertPolicy: AlertPolicy,
    formState: AlertFormState
  ) => Promise<ApiAlertPolicy>;
  enrollFeaturesInAlertPolicy: (
    alertPolicy: AlertPolicy,
    featureIds: number[],
    showFetching?: boolean
  ) => Promise<ApiAlertEnrollment[]>;
  unenrollFeaturesInAlertPolicy: (
    alertPolicy: AlertPolicy,
    featureIds: number[]
  ) => Promise<{alertPolicy: AlertPolicy; featureIds: number[]}>;
  archiveAlertPolicy: (alertPolicyId: number) => Promise<ApiAlertPolicy>;
}

function configureAlertProperties(properties: AlertTypeProperties): APIAlertPolicyProperties {
  return {
    ...snakeCaseKeys(properties),
    ...((properties as ThresholdAlertProperties).percentOfProperty
      ? {
          percent_of_property:
            (properties as ThresholdAlertProperties).percentOfProperty / 100 || 1,
        }
      : {}),
  };
}

export const AlertPolicyContextProvider = ({
  children,
  featureCollection,
}: {
  children: React.ReactNode;
  featureCollection: I.ImmutableOf<ApiFeatureCollection>;
}) => {
  const featureCollectionId = featureCollection.get('id');
  const pushNotification = usePushNotification();
  const queryClient = useQueryClient();

  // Fetch alert policies
  const {
    data: alertPolicies = [],
    isFetching,
    error,
  } = useQuery({
    queryKey: ['alertPolicies', featureCollectionId],
    queryFn: async (): Promise<AlertPolicy[]> => {
      const apiAlertPolicies: I.ImmutableListOf<ApiAlertPolicy> = (
        await api.featureCollections.getAlertPolicies(featureCollectionId)
      ).get('data');
      return camelCaseKeys(apiAlertPolicies.toJS()) as AlertPolicy[];
    },
  });

  // Reusable function to display error notifications
  function handleError(message: string, e: unknown) {
    console.error(e);
    pushNotification({
      message,
      options: {intent: B.Intent.DANGER},
    });
  }

  // CREATE
  const createAlertPolicyMutation = useMutation({
    mutationFn: async ({
      featureCollection,
      formState,
    }: {
      featureCollection: I.ImmutableOf<ApiFeatureCollection>;
      formState: AlertFormState;
    }) => {
      const {sourceId, layerId} = layerUtils.parseLayerKey(formState.layer.key);
      const response = await api.featureCollections.createAlertPolicy({
        featureCollectionId: featureCollection.get('id'),
        sourceId,
        layerId,
        alertType: layerUtils.isDataLayer(formState.layer)
          ? CUSTOM_ALERT_TYPE_THRESHOLD
          : CUSTOM_ALERT_TYPE_CATEGORY,
        properties: configureAlertProperties(formState.properties),
        name: formState.name,
        description: formState.description,
      });
      return response.get('data').toJS() as AlertPolicy;
    },
    onSuccess: (newAlertPolicy, {featureCollection, formState}) => {
      const camelNewAlertPolicy = camelCaseKeys(newAlertPolicy);
      pushNotification({
        message: `Lookout policy "${camelNewAlertPolicy.name}" created successfully`,
        options: {intent: B.Intent.SUCCESS},
      });
      recordEvent('Created Lookout policy', {
        name: camelNewAlertPolicy.name,
        layer: formState.layer.key,
      });
      // Update the local cache after successful mutation
      // (instead of refetch)
      queryClient.setQueryData<AlertPolicy[]>(
        ['alertPolicies', featureCollection.get('id')],
        (prev = []) => [...prev, {...camelNewAlertPolicy, enrollments: []}]
      );
    },
    onError: (err) => {
      handleError(FAILED_CREATE_ERROR, err);
    },
  });

  // UPDATE
  const updateAlertPolicyMutation = useMutation({
    mutationFn: async ({
      alertPolicy,
      formState,
    }: {
      alertPolicy: AlertPolicy;
      formState: AlertFormState;
    }) => {
      const {sourceId, layerId} = layerUtils.parseLayerKey(formState.layer.key);
      const response = await api.featureCollections.updateAlertPolicy({
        id: alertPolicy.id,
        featureCollectionId: alertPolicy.featureCollectionId,
        sourceId,
        layerId,
        alertType: formState.alertType,
        properties: configureAlertProperties(formState.properties),
        name: formState.name,
        description: formState.description,
      });
      return response.get('data').toJS() as AlertPolicy;
    },
    onSuccess: (updatedPolicy, {alertPolicy: prevAlertPolicy}) => {
      const camelUpdatedPolicy = camelCaseKeys(updatedPolicy);
      pushNotification({
        message: `Alert policy "${camelUpdatedPolicy.name}" updated successfully`,
        options: {intent: B.Intent.SUCCESS},
      });
      // Update cache
      queryClient.setQueryData<AlertPolicy[]>(
        ['alertPolicies', prevAlertPolicy.featureCollectionId],
        (prev = []) => {
          return prev.map((p) =>
            p.id === camelUpdatedPolicy.id
              ? {...camelUpdatedPolicy, enrollments: prevAlertPolicy.enrollments}
              : p
          );
        }
      );
    },
    onError: (err) => {
      handleError(FAILED_CREATE_ERROR, err);
    },
  });

  // ARCHIVE
  const archiveAlertPolicyMutation = useMutation({
    mutationFn: async (alertPolicyId: number) => {
      const response = await api.featureCollections.archiveAlertPolicy(
        featureCollectionId,
        alertPolicyId
      );
      return response.get('data').toJS() as AlertPolicy;
    },
    onMutate: (alertPolicyId) => {
      // Cancel any outgoing refetches
      queryClient.cancelQueries({
        queryKey: ['alertPolicies', featureCollectionId],
      });
      const previousPolicies = queryClient.getQueryData<AlertPolicy[]>([
        'alertPolicies',
        featureCollectionId,
      ]);
      queryClient.setQueryData<AlertPolicy[]>(
        ['alertPolicies', featureCollectionId],
        (previousPolicies = []) =>
          previousPolicies.filter((previousPolicy) => previousPolicy.id !== alertPolicyId)
      );

      // This Fn returns the context that onError will receive
      return {previousPolicies};
    },
    onSuccess: (archivedPolicy) => {
      pushNotification({
        message: `${archivedPolicy.name} successfully deleted`,
        options: {intent: B.Intent.SUCCESS},
      });
    },
    onError: (err, _alertPolicyId, context) => {
      handleError(FAILED_DELETE_ERROR, err);
      // rollback on error
      if (context?.previousPolicies) {
        queryClient.setQueryData<AlertPolicy[]>(
          ['alertPolicies', featureCollectionId],
          context.previousPolicies
        );
      } else {
        // invalidate cache if no previous policies
        queryClient.invalidateQueries({
          queryKey: ['alertPolicies', featureCollectionId],
        });
      }
    },
  });

  // ENROLL
  const enrollFeaturesMutation = useMutation({
    mutationFn: async ({
      alertPolicy,
      featureIds,
    }: {
      alertPolicy: AlertPolicy;
      featureIds: number[];
    }) => {
      const response = await api.featureCollections.enrollFeaturesInAlertPolicy(
        alertPolicy.featureCollectionId,
        alertPolicy.id,
        featureIds
      );
      return response.get('data').toJS() as ApiAlertEnrollment[];
    },
    onSuccess: (updatedEnrollments, {alertPolicy}) => {
      pushNotification({
        message: `Successfully enrolled in ${alertPolicy.name}`,
        options: {intent: B.Intent.SUCCESS},
        shouldClearPrevToast: false,
      });
      // Update cache
      queryClient.setQueryData<AlertPolicy[]>(
        ['alertPolicies', alertPolicy.featureCollectionId],
        (prev = []) =>
          prev.map((policy) =>
            policy.id === alertPolicy.id
              ? {...policy, enrollments: [...policy.enrollments, ...updatedEnrollments]}
              : policy
          )
      );
    },
    onError: (err) => {
      handleError(FAILED_CREATE_ERROR, err);
    },
  });

  // UNENROLL
  const unenrollFeaturesMutation = useMutation({
    mutationFn: async ({
      alertPolicy,
      featureIds,
    }: {
      alertPolicy: AlertPolicy;
      featureIds: number[];
    }) => {
      await api.featureCollections.unenrollFeaturesInAlertPolicy(
        alertPolicy.featureCollectionId,
        alertPolicy.id,
        featureIds
      );
      return {alertPolicy, featureIds};
    },
    onMutate: ({alertPolicy, featureIds}) => {
      // It's important to cancel any outgoing refetches
      // so that we don't overwrite any optimistic updates
      // with stale data
      queryClient.cancelQueries({
        queryKey: ['alertPolicies', alertPolicy.featureCollectionId],
      });

      // Store the previous policies in case of error so we can rollback
      const previousPolicies = queryClient.getQueryData<AlertPolicy[]>([
        'alertPolicies',
        alertPolicy.featureCollectionId,
      ]);

      // Update cache
      queryClient.setQueryData<AlertPolicy[]>(
        ['alertPolicies', alertPolicy.featureCollectionId],
        (previousPolicies = []) => {
          return previousPolicies.map((policy) =>
            policy.id === alertPolicy.id
              ? {
                  ...policy,
                  enrollments: policy.enrollments.filter(
                    (enrollment) => !featureIds.includes(enrollment.featureId)
                  ),
                }
              : policy
          );
        }
      );

      // This Fn returns the context that onError will receive
      return {previousPolicies};
    },
    onSuccess: ({alertPolicy}) => {
      pushNotification({
        message: `Successfully unenrolled from ${alertPolicy.name}`,
        options: {intent: B.Intent.SUCCESS},
        shouldClearPrevToast: false,
      });
    },
    onError: (err, _variables, context) => {
      handleError(FAILED_CREATE_ERROR, err);
      // rollback on error
      if (context?.previousPolicies) {
        queryClient.setQueryData<AlertPolicy[]>(
          ['alertPolicies', featureCollectionId],
          context.previousPolicies
        );
      } else {
        // invalidate cache if no previous policies
        queryClient.invalidateQueries({
          queryKey: ['alertPolicies', featureCollectionId],
        });
      }
    },
  });

  // Map the mutation calls back to our context actions
  const actions: AlertPolicyActions = {
    createAlertPolicy: (fc, fs) =>
      createAlertPolicyMutation.mutateAsync({featureCollection: fc, formState: fs}),
    updateAlertPolicy: (policy, fs) =>
      updateAlertPolicyMutation.mutateAsync({alertPolicy: policy, formState: fs}),
    enrollFeaturesInAlertPolicy: (policy, featureIds) =>
      enrollFeaturesMutation.mutateAsync({alertPolicy: policy, featureIds}),
    unenrollFeaturesInAlertPolicy: (policy, featureIds) =>
      unenrollFeaturesMutation.mutateAsync({alertPolicy: policy, featureIds}),
    archiveAlertPolicy: (alertPolicyId) => archiveAlertPolicyMutation.mutateAsync(alertPolicyId),
  };

  // General query status
  const meta = {
    isFetching,
    error,
  };

  return (
    <AlertPolicyContext.Provider value={{alertPolicies, actions, meta}}>
      {children}
    </AlertPolicyContext.Provider>
  );
};

export const FakeAlertPolicyProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    alertPolicies: I.ImmutableListOf<AlertPolicy>;
    action?: (name: string) => () => any;
  }>
> = ({children, alertPolicies, action = () => () => null}) => {
  const value = React.useMemo(
    (): AlertPolicyContextValue => ({
      alertPolicies: [],
      actions: {
        createAlertPolicy: action('createAlertPolicy'),
        updateAlertPolicy: action('updateAlertPolicy'),
        enrollFeaturesInAlertPolicy: action('enrollFeaturesInAlertPolicy'),
        unenrollFeaturesInAlertPolicy: action('unenrollFeaturesInAlertPolicy'),
        archiveAlertPolicy: action('archiveAlertPolicy'),
      },
      meta: {
        isFetching: alertPolicies.size === 0, // should be ok for test
        error: null,
      },
    }),
    [alertPolicies, action]
  );

  return <AlertPolicyContext.Provider value={value}>{children}</AlertPolicyContext.Provider>;
};

export function useAlertPolicies(): AlertPolicyContextValue {
  const value = React.useContext(AlertPolicyContext);

  if (!value) {
    throw new Error('useAlertPolicies must be beneath an AlertPolicyContextProvider');
  }

  return value;
}
