import * as B from '@blueprintjs/core';
import {DatePicker} from '@blueprintjs/datetime';
import shpwrite from '@mapbox/shp-write';
import {VirtualItem} from '@tanstack/react-virtual';
import classnames from 'classnames';
import {ExportToCsv} from 'export-to-csv';
import * as I from 'immutable';
import matchSorter from 'match-sorter';
import moment from 'moment-timezone';
import React from 'react';
import MultiLineEllipsis from 'react-dotdotdot';
import DynamicOverflow from 'react-dynamic-overflow';
import {Link} from 'react-router-dom';
import {
  Column,
  ColumnInstance,
  Filters,
  FilterProps as ReactTableFilterProps,
  Row,
  SortingRule,
  TableInstance,
  TableState,
  UseSortByState,
  useExpanded,
  useFilters,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

import {useAlertPolicies} from 'app/components/Alerts/AlertPolicyProvider';
import LookoutIcon from 'app/components/Alerts/LookoutIcon';
import FeatureMiniMap from 'app/components/FeatureMiniMap/FeatureMiniMap';
import {PortfolioLayersSelector} from 'app/components/Library/PortfolioLayersSelector';
import List from 'app/components/List';
import {
  ApiFeature,
  ApiFeatureCommonProperties,
  SystemPaidImagery,
} from 'app/modules/Remote/Feature';
import {
  ApiAlertCountsByFeatureId,
  ApiFeatureCollection,
} from 'app/modules/Remote/FeatureCollection';
import {ApiOrganization, ApiOrganizationUser, getAreaUnit} from 'app/modules/Remote/Organization';
import {ApiProject} from 'app/modules/Remote/Project';
import EditTagsMenu from 'app/pages/MonitorProjectView/EditTagsMenu';
import {changeSelection} from 'app/pages/ProjectDashboardSwitch';
import {FeaturesActions} from 'app/providers/FeaturesProvider';
import {recordEvent} from 'app/tools/Analytics';
import * as CONSTANTS from 'app/utils/constants';
import * as conversionUtils from 'app/utils/conversionUtils';
import * as featureCollectionUtils from 'app/utils/featureCollectionUtils';
import featureFlags from 'app/utils/featureFlags';
import * as featureUtils from 'app/utils/featureUtils';
import {
  APP_PROPERTY_NAMES,
  AppProperty,
  FeatureAttributeExport,
  FeatureAttributes,
} from 'app/utils/featureUtils';
import * as geoJsonUtils from 'app/utils/geoJsonUtils';
import * as hookUtils from 'app/utils/hookUtils';
import * as immutableUtils from 'app/utils/immutableUtils';
import {
  getMultiFeaturePropertyParts,
  sortMultiFeaturePropertyPartsCallback,
} from 'app/utils/multiFeaturePropertyUtils';
import * as routeUtils from 'app/utils/routeUtils';
import * as tagUtils from 'app/utils/tagUtils';
import * as userUtils from 'app/utils/userUtils';

import {
  AlertCountFilter,
  AssigneeFilter,
  DateFilter,
  FilterProps,
  ImageryFilterValue,
  ImageryStatusFilter,
  NoteCountFilter,
  NoteCountFilterValue,
  SearchBoxFilter,
  TableTagFilter,
  decodeAlertCountFilterValue,
  makeTagsFilter,
} from './filters';
import MovePropertiesMenu from './MovePropertiesMenu';
import {ImageryNotificationsByFeatureId} from './PropertyOverview';
import * as cs from './PropertyOverview.styl';
import useFiltersPatch from './useFiltersPatch';
import {getAlertListItems, makeUserListItems} from './utils';

import {NoteCountsByFeatureId} from '.';

export interface PropertyTableRow {
  features: I.ImmutableOf<ApiFeature[]>;
  subRows?: PropertyTableRow[];
}

/** Text filter that uses matchSorter's fuzzy matcher rather than the default contains. */
const fuzzyFilter = Object.assign(
  (rows: Row<PropertyTableRow>[], id: string, filterValue: string) => {
    const containsStringItems = matchSorter(rows, filterValue, {
      keys: [
        // Match if property name contains filterValue
        (row: Row<PropertyTableRow>) => row.values[id],
        // Also match if a row contains any subrows where the
        // multilocation property name contains filterValue
        (row: Row<PropertyTableRow>) =>
          row.original.features?.map((feature) =>
            feature?.getIn(['properties', 'multiFeaturePartName'])
          ),
      ],
      threshold: matchSorter.rankings.CONTAINS,
    });

    // Match if row id (name, for example) has acronym filterValue
    const acronymItems = matchSorter(rows, filterValue, {
      keys: [(row: Row<PropertyTableRow>) => row.values[id]],
      threshold: matchSorter.rankings.ACRONYM,
    });

    // Generate a set of ids to match against
    const filteredItems = new Set([...containsStringItems, ...acronymItems].map((i) => i.id));

    // Filter rows by ids in filteredItems
    return rows.filter((r) => filteredItems.has(r.id));
  },
  // Let the table remove the filter if the string is empty
  {autoRemove: (val: string) => !val}
);

/**
 * "make" function so we can parameterize it over whether or not we're treating
 * hidden imagery as available.
 */
const makeImageryFilter =
  (isHiddenImageryShownAsAvailable: boolean) =>
  (
    rows: Row<PropertyTableRow>[],
    _,
    filterValue: ImageryFilterValue = 'all'
  ): Row<PropertyTableRow>[] => {
    if (filterValue === 'all') {
      return rows;
    } else {
      return rows.filter((r) => {
        const flatRows = r.subRows?.length ? r.subRows : [r];

        const imageryStatuses: ImageryStatus[] = flatRows.map((r) => r.values['imagery']);

        const getAvailableCount = (s: ImageryStatus) =>
          s.available.length + (isHiddenImageryShownAsAvailable ? s.hidden.length : 0);

        const getAvailableSubMeterCount = (s: ImageryStatus) =>
          s.availableSubMeter.length +
          (isHiddenImageryShownAsAvailable ? s.hiddenSubMeter.length : 0);

        const getPurchasedCount = (s: ImageryStatus) => s.purchased.length;
        const getPurchasedSubMeterCount = (s: ImageryStatus) => s.purchasedSubMeter.length;

        return (
          // If we are filtering for rows with purchased imagery, return rows
          // where some of its features have some purchased images.
          // If we are filtering for rows with no available or purchased imagery,
          // return rows where none of its features have available or purchased
          // images.
          (filterValue === 'purchased' && imageryStatuses.some((s) => getPurchasedCount(s))) ||
          // If we are filtering for rows with available but not purchased
          // imagery, return rows where some of its features have some available
          // images but no purchased images.
          (filterValue === 'not-purchased' &&
            imageryStatuses.some((s) => getAvailableCount(s) && !getPurchasedCount(s))) ||
          // If we are filtering for rows with available but not purchased sub-meter imagery,
          // return rows where some of its features have some sub-meter resolution images available
          // but no purchased sub-meter images.
          (filterValue === 'not-purchased-submeter' &&
            imageryStatuses.some(
              (s) => getAvailableSubMeterCount(s) && !getPurchasedSubMeterCount(s)
            )) ||
          (filterValue === 'none' &&
            imageryStatuses.some((s) => !getAvailableCount(s) && !getPurchasedCount(s)))
        );
      });
    }
  };

/**
 * Collection of our react-table filter types, to be used by columns.
 */
const FILTER_TYPES = {
  fuzzy: fuzzyFilter,
};

export interface ImageDetails {
  date: string;
  sourceId: string | null;
  /** Technically redundant, since we bucket in ImageryStatus, but makes it
   * easier to filter when constructing that object. */
  isHidden?: boolean;
  resolution?: number;
}

/** Type for the "High-Res Imagery" cell data */
export interface ImageryStatus {
  available: ImageDetails[];
  availableSubMeter: ImageDetails[];
  purchased: ImageDetails[];
  purchasedSubMeter: ImageDetails[];
  hidden: ImageDetails[];
  hiddenSubMeter: ImageDetails[];
}

/**
 * Input element wrapper to handle indeterminate state.
 */
const InputWrapper: React.FunctionComponent<
  React.PropsWithChildren<
    {
      state: 'checked' | 'indeterminate' | 'unchecked';
    } & React.HTMLProps<HTMLInputElement>
  >
> = ({state, ...inputProps}) => {
  const ref = React.useRef<HTMLInputElement>(null);

  // React doesn't have a declarative prop for the indeterminate state, so we
  // need to set it ourselves when some but not all rows are selected.
  React.useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = state === 'indeterminate';
    }
  }, [state]);

  return <input ref={ref} type="checkbox" checked={state === 'checked'} {...inputProps} />;
};

/**
 * Wrapper around react-table's useTable that configures it with the columns
 * and filters that we're using.
 */
export function usePropertyTable({
  year,
  groupedFeatures,
  imageryByFeatureId,
  userNoteCountsByFeatureId,
  alertNoteCountsByFeatureId,
  organizationUsers,
  organization,
  projectId,
  featureCollection,
  allowSelection,
  savedFilterState,
  savedSortState,
  savedSelectedRowIds,
  showImageryPurchaseDrawer,
  batchPatchFeature,
  openCustomizeTagsModal,
  isExpandedForTest,
  setYear,
}: {
  projectId: string;
  featureCollection: I.ImmutableOf<ApiFeatureCollection>;
  year: number;
  groupedFeatures: I.ImmutableListOf<ApiFeature>[];
  imageryByFeatureId: ImageryNotificationsByFeatureId | null;
  userNoteCountsByFeatureId: hookUtils.StatusMaybe<NoteCountsByFeatureId>;
  alertNoteCountsByFeatureId: hookUtils.StatusMaybe<ApiAlertCountsByFeatureId>;
  organization: I.ImmutableOf<ApiOrganization>;
  organizationUsers: ApiOrganizationUser[];
  allowSelection: boolean;
  savedFilterState: Filters<I.ImmutableOf<ApiFeature[]>>;
  savedSortState: SortingRule<I.ImmutableListOf<ApiFeature>>[];
  savedSelectedRowIds: Record<string, boolean>;
  showImageryPurchaseDrawer: (
    features: I.ImmutableOf<ApiFeature[]>,
    activeFeatureId: number | null
  ) => void;
  batchPatchFeature: FeaturesActions['batchPatchFeature'];
  openCustomizeTagsModal: (() => void) | null;
  isExpandedForTest?: boolean;
  setYear: (year: number) => void;
}) {
  const featureCollectionId = featureCollection.get('id');
  const tagSettings = hookUtils.useContinuity(
    tagUtils.getTagSettings(featureCollection, tagUtils.TAG_KIND.FEATURE),
    I.is
  );

  // If true, hidden imagery is treated as avaliable in the filter and display.
  const [isHiddenImageryShownAsAvailable, setIsHiddenImageryShownAsAvailable] =
    React.useState(false);

  const areaUnit = getAreaUnit(organization);

  let years = featureCollectionUtils.getProcessedImageryYears(featureCollection);
  years = hookUtils.useContinuity(years);

  const {alertPolicies} = useAlertPolicies();

  // TODO(fiona): This had been getting recreated on every render based on
  // something in the deps. Change to be more resilient and also move some of
  // the components out into actual separate components so that their types
  // don't change (which breaks reconciliation).
  const columns = React.useMemo<Column<PropertyTableRow>[]>(() => {
    const alertsColumns: Column<PropertyTableRow>[] = featureFlags.LOOKOUTS(organization)
      ? [
          {
            id: 'alertNoteCount',
            Header: 'Lookouts',
            className: cs.cellAlerts,
            loading: alertNoteCountsByFeatureId.status == 'unknown',
            Filter: (props: FilterProps) => (
              <AlertCountFilter
                {...props}
                alertPolicies={alertPolicies}
                selectedFeatureCollection={featureCollection}
              />
            ),
            filter: (rows: Row<PropertyTableRow>[], _, filterValue: string) => {
              if (!filterValue) {
                return rows;
              } else {
                const value = decodeAlertCountFilterValue(filterValue);

                // Return true if any sub row (multi location property) has matching alert policies
                return rows.filter((r) => {
                  const flatRows = r.subRows?.length ? r.subRows : [r];

                  return flatRows.some((r) => {
                    const allAlertCounts = alertNoteCountsByFeatureId.value?.[r.id] || {};

                    const alertPolicies = Object.keys(allAlertCounts);
                    return alertPolicies.some((policy) => value.includes(policy));
                  });
                });
              }
            },
            accessor: ({features}) => {
              if (
                alertNoteCountsByFeatureId.status == 'unknown' ||
                !alertNoteCountsByFeatureId.value
              ) {
                // if not loaded, show empty cells
                return null;
              }

              const sum = features.reduce((acc = 0, f) => {
                if (!f) {
                  return acc;
                }
                const count = alertNoteCountsByFeatureId.value[f.get('id')]?.['all'] || 0;
                return acc + count;
              }, 0);
              return sum;
            },
            Cell: ({cell, row}) => {
              if (row.isExpanded) {
                // Blank out if expanded since information is represented by sub-rows.
                return null;
              }

              return (
                <span
                  className={classnames(cs.alertCountBadge, {
                    [cs.alertCountBadgeActive]: !!cell.value,
                  })}
                >
                  {!!cell.value && cell.value}
                </span>
              );
            },
          } as Column<PropertyTableRow>,
        ]
      : [];

    return [
      ...(allowSelection
        ? [
            {
              id: 'selection',
              className: cs.cellSelect,
              Header: ({
                flatRows,
                isAllRowsSelected,
                toggleAllRowsSelected,
                rows,
                toggleExpanded,
              }) => {
                const expandableRows = rows.filter((r) => r.canExpand);

                const areAllRowsExpanded =
                  expandableRows.length === rows.filter((r) => r.isExpanded).length;

                const icon = expandableRows.length
                  ? areAllRowsExpanded
                    ? 'collapse-all'
                    : 'expand-all'
                  : 'blank';

                return (
                  <div className={cs.selectionInputWrapper} onClick={() => toggleAllRowsSelected()}>
                    <B.Button
                      small
                      minimal
                      icon={icon}
                      style={{...(icon === 'blank' && {visibility: 'hidden'})}}
                      onClick={(e) => {
                        e.stopPropagation();
                        expandableRows.forEach((r) => {
                          toggleExpanded([r.id], areAllRowsExpanded ? false : true);
                        });
                      }}
                      title={areAllRowsExpanded ? 'Collapse all' : 'Expand all'}
                    />

                    <InputWrapper
                      onChange={() => toggleAllRowsSelected()}
                      state={
                        isAllRowsSelected
                          ? 'checked'
                          : getSelectedPropertyRows(flatRows).length
                            ? 'indeterminate'
                            : 'unchecked'
                      }
                    />
                  </div>
                );
              },
              Cell: ({row}) => {
                const isExpanded = row.canExpand && row.isExpanded;
                const isCollapsed = row.canExpand && !row.isExpanded;

                return (
                  <div
                    className={cs.selectionInputWrapper}
                    onClick={() => row.toggleRowSelected(!row.isSelected)}
                  >
                    {getIsSubRow(row) ? (
                      <B.Icon
                        icon="key-enter"
                        style={{transform: 'scaleX(-1)', marginRight: '7px'}}
                      />
                    ) : (
                      <B.Button
                        small
                        minimal
                        icon={isExpanded ? 'chevron-down' : isCollapsed ? 'chevron-right' : 'blank'}
                        style={{...(!row.canExpand && {visibility: 'hidden'})}}
                        onClick={(e) => {
                          e.stopPropagation();
                          row.canExpand && row.toggleExpanded();
                        }}
                        title={isExpanded ? 'Collapse' : isCollapsed ? 'Expand' : undefined}
                      />
                    )}

                    <InputWrapper
                      tabIndex={-1}
                      onChange={(event) => row.toggleRowSelected(!!event.currentTarget.checked)}
                      state={
                        getIsSomeSelected(row)
                          ? 'indeterminate'
                          : row.isSelected
                            ? 'checked'
                            : 'unchecked'
                      }
                    />
                  </div>
                );
              },
            },
          ]
        : []),
      {
        id: 'name',
        Header: 'Name',
        Filter: () => null,
        filter: 'fuzzy',
        accessor: ({features}) => features.first().getIn(['properties', 'name']),
        Cell: ({cell, row, rows}) => {
          const {features} = cell.row.original;

          const href = routeUtils.makeProjectDashboardUrl(
            organization,
            projectId,
            'map',
            features.toArray()
          );

          const locateLink = features.first().getIn(['properties', 'LOCATELink'] as any) as
            | string
            | undefined;

          let title = cell.value,
            name = cell.value;

          const isProcessing =
            features.first().getIn(['properties', 'status']) ===
            CONSTANTS.FEATURE_WAITING_FOR_PROCESSING_STATUS;

          if (getIsSubRow(row)) {
            const parentRow = getParentRow(row, rows);
            const parentFeatures = parentRow.original.features;

            const multiFeaturePropertyParts = getMultiFeaturePropertyParts(
              parentFeatures,
              features.first()
            );

            const activePart = multiFeaturePropertyParts.find((p) => p.isActive);

            // Satisfy type safety check. We know this will exist since we know
            // the feature represented by the row exists in the
            // parentDatum.features list.
            if (activePart) {
              const hasPartName = !!activePart.partName;
              title = activePart.partName || 'Location';
              name = (
                <B.Popover
                  interactionKind="hover"
                  content={
                    <FeatureMiniMap
                      features={parentFeatures}
                      selectedFeatureId={activePart.feature.get('id')}
                      setSelectedFeatureId={() => {}}
                      hoveredFeatureId={null}
                      hoverFeatureById={() => {}}
                      height={200}
                      width={200}
                      padding={10}
                    />
                  }
                >
                  {hasPartName ? title : <i>{title}</i>}
                </B.Popover>
              );
            }
          }

          return (
            <div style={{display: 'flex', alignItems: 'center'}}>
              {/* Hack for SPNHF to show a link to LOCATE */}
              {locateLink && (
                <B.Tooltip content="View in LOCATE" position="top">
                  <B.AnchorButton
                    icon="link"
                    href={locateLink}
                    target="_blank"
                    minimal
                    onClick={(ev) => ev.stopPropagation()}
                  />
                </B.Tooltip>
              )}

              <Link to={href} title={title}>
                {/* Name of the property, or if a multi-location part, named of
                the property wrapped in a popover containing a mini-map of the
                property and selected part */}
                <MultiLineEllipsis clamp={3}>{name}</MultiLineEllipsis>
              </Link>
              {/* Show processing unless the row is expanded, in which case we'll show this status for children */}
              {isProcessing && !row.isExpanded ? (
                <B.Tooltip content={'Imagery is loading'} position={'bottom'}>
                  <span className={cs.cellProcessingState}>
                    <B.Icon intent={B.Intent.WARNING} icon="time" size={16} />
                  </span>
                </B.Tooltip>
              ) : (
                <span className={cs.cellProcessingState}>
                  <B.Icon icon="blank" size={16} />
                </span>
              )}
            </div>
          );
        },
      },
      {
        id: 'tags',
        Header: 'Tags',
        Filter: (props: FilterProps) => <TableTagFilter {...props} tagSettings={tagSettings} />,
        filter: makeTagsFilter(tagSettings),
        accessor: ({features}) => tagUtils.getFeaturesTagIds(features),
        Cell: ({row, cell}) => {
          const tagIds: ReturnType<typeof tagUtils.getFeatureTagIds> = cell.value;

          const activeTags = tagSettings.filter((t) => tagIds.includes(t!.get('id'))).toList();

          const [showAddTagsBtn, setShowAddTagsBtn] = React.useState(false);

          const {features} = cell.row.original;

          const tagCounts = tagUtils.getFeaturesTagCounts(features);

          // Blank out if expanded since information is represented by sub-rows.
          if (row.isExpanded) {
            return null;
          }

          return (
            // DynamicOverflow counts how many elements (tags in this case) are visible, and
            // how many have overflowed its container
            <DynamicOverflow
              list={({tabRef}) =>
                activeTags
                  .map((t) => (
                    <div ref={tabRef} key={t!.get('id')}>
                      <tagUtils.Tag
                        setting={t!}
                        indeterminate={tagCounts.get(t!.get('id')) !== features.size}
                        className={cs.cellTag}
                      />
                    </div>
                  ))
                  .toArray()
              }
            >
              {({visibleElements, overflowElements, containerRef}) => {
                return (
                  <div ref={containerRef}>
                    <EditTagsMenu
                      featureCollectionId={featureCollectionId}
                      tagSettings={tagSettings}
                      tagStatusMaps={tagUtils.getFeaturesTagStatusMaps(features)}
                      onChange={(tagStatusMap) =>
                        batchPatchFeature({
                          featureCollectionId: featureCollection.get('id'),
                          features,
                          changes: featureUtils.tagStatusMapToPropertiesChanges(tagStatusMap),
                        })
                      }
                      openCustomizeTagsModal={openCustomizeTagsModal}
                      onClose={() => setShowAddTagsBtn(false)}
                      kind={tagUtils.TAG_KIND.FEATURE}
                    >
                      {activeTags.size ? (
                        <B.Button minimal className={cs.cellTagsBtn}>
                          <div style={{width: 'inherit'}}>
                            <div className={cs.cellTags}>{visibleElements}</div>
                            <p className={cs.cellTagsMore}>
                              {overflowElements.length > 0 && (
                                <span>+ {overflowElements.length} more...</span>
                              )}
                            </p>
                          </div>
                        </B.Button>
                      ) : (
                        <B.Button
                          className={classnames(cs.cellAddTagsBtn, {[cs.show]: showAddTagsBtn})}
                          minimal
                          // Styling as a B.Tag wrapped in a div for visual consistency
                          // with the tagUtils.Tag component.
                          text={
                            <div className={cs.cellTags}>
                              <B.Tag minimal>Add Property Tags…</B.Tag>
                            </div>
                          }
                          // In addition to showing the "Add Property Tags" button when the user
                          // hovers over the property row (which we handle with pure
                          // CSS), we also want to show it when EditTagsMenu is open
                          // open so the target button doesn't disappear when the user
                          // stops hovering over the property row to interact with
                          // EditTagsMenu content.
                          onClick={() =>
                            setShowAddTagsBtn((prevShowAddTagsBtn) => !prevShowAddTagsBtn)
                          }
                        />
                      )}
                    </EditTagsMenu>
                  </div>
                );
              }}
            </DynamicOverflow>
          );
        },
      },
      {
        id: 'area',
        Header: areaUnit === 'areaHectare' ? 'Hectares' : 'Acres',
        className: cs.cellArea,
        accessor: ({features}) =>
          features.reduce((acc, f) => acc! + featureUtils.getFeatureAreaM2(f!), 0),
        Cell: ({row, cell}) => {
          // Blank out if expanded since information is represented by sub-rows.
          if (row.isExpanded) {
            return null;
          }
          // We use the same calculation as for imagery ordering so that the
          // numbers are consistent.
          return conversionUtils.numberWithCommas(
            featureUtils.featureM2ToArea(cell.value, {unit: areaUnit})
          );
        },
      },
      {
        id: 'imagery',
        Header: (
          <div className={cs.cellWithYearSelectHeader}>
            <div>High-Res</div>

            <B.HTMLSelect value={year} onChange={(e) => setYear(Number(e.target.value))}>
              {years.map((y) => (
                <option key={y} value={y}>
                  '{y.toString().slice(-2)}
                </option>
              ))}
            </B.HTMLSelect>
          </div>
        ),
        loading: imageryByFeatureId === null,
        Filter: (props: FilterProps) => (
          <ImageryStatusFilter
            {...props}
            isHiddenImageryShownAsAvailable={isHiddenImageryShownAsAvailable}
            setIsHiddenImageryShownAsAvailable={setIsHiddenImageryShownAsAvailable}
          />
        ),
        filter: makeImageryFilter(isHiddenImageryShownAsAvailable),
        className: cs.cellHighRes,
        accessor: imageryAccessor.bind(null, imageryByFeatureId, year, featureCollection),
        Cell: ({cell, row, rows}) => {
          // Blank out if expanded since information is represented by sub-rows.
          if (row.isExpanded) {
            return null;
          }

          const isSubRow = getIsSubRow(row);

          return (
            <HighResImageryCell
              cell={cell}
              isHiddenImageryShownAsAvailable={isHiddenImageryShownAsAvailable}
              showImageryPurchaseDrawer={() =>
                showImageryPurchaseDrawer(
                  // If sub-row, show all features associated with parent (property) row.
                  isSubRow ? getParentRow(row, rows).original.features : row.original.features,

                  // If sub-row, style the part represented by this row as active.
                  isSubRow ? row.original.features.first().get('id') : null
                )
              }
            />
          );
        },
      },
      {
        id: 'userNoteCount',
        Header: (
          <div className={cs.cellWithYearSelectHeader}>
            <div>Notes</div>

            <B.HTMLSelect value={year} onChange={(e) => setYear(Number(e.target.value))}>
              {years.map((y) => (
                <option key={y} value={y}>
                  '{y.toString().slice(-2)}
                </option>
              ))}
            </B.HTMLSelect>
          </div>
        ),
        loading: userNoteCountsByFeatureId.status == 'unknown',
        Filter: NoteCountFilter,
        filter: (rows: Row<PropertyTableRow>[], _, filterValue: NoteCountFilterValue = null) => {
          if (filterValue === null) {
            return rows;
          } else {
            return rows.filter((r) => {
              const flatRows = r.subRows?.length ? r.subRows : [r];

              const noteCounts = flatRows.map((r) => r.values.userNoteCount);

              return (
                (filterValue === '>0' && noteCounts.some((n) => n >= 1)) ||
                (filterValue === '0' && noteCounts.some((n) => n == 0))
              );
            });
          }
        },
        className: cs.cellNoteCount,
        accessor: ({features}) => {
          if (userNoteCountsByFeatureId.status == 'unknown' || !userNoteCountsByFeatureId.value) {
            // if not loaded, show empty cells
            return null;
          }

          const sum = features.reduce((acc, f) => {
            if (!f) {
              return acc;
            }
            const count = userNoteCountsByFeatureId.value.getIn([f.get('id'), year]) || 0;
            return acc + count;
          }, 0);
          return sum;
        },
        Cell: ({cell, row}) => {
          if (row.isExpanded) {
            // Blank out if expanded since information is represented by sub-rows.
            return null;
          }

          return cell.value;
        },
      },
      // this is generated above so that we can have a feature flag
      ...alertsColumns,
      {
        id: 'assignee',
        Header: APP_PROPERTY_NAMES[CONSTANTS.APP_PROPERTY_ASSIGNEE_EMAIL],
        className: cs.cellAssignee,
        // We wrap AssigneeFilter so we can provide it organizationUsers.
        Filter: (props: ReactTableFilterProps<I.ImmutableOf<ApiFeature[]>>) => (
          <AssigneeFilter organizationUsers={organizationUsers} {...props} />
        ),
        filter: getAssigneeFilter,
        Cell: ({row, cell}) => {
          // Blank out if expanded since information is represented by sub-rows.
          if (row.isExpanded) {
            return null;
          }

          if (cell.value === 'Multiple assignees') {
            return <i>{cell.value}</i>;
          }

          const user = organizationUsers.find((u) => u!.email === cell.value);
          return user ? userUtils.getSuffixedUserName(user) : cell.value;
        },
        accessor: ({features}) => {
          const ASSIGNEE_PATH = [
            'properties',
            CONSTANTS.APP_PROPERTIES_KEY,
            CONSTANTS.APP_PROPERTY_ASSIGNEE_EMAIL,
          ];

          const firstFeatureValue = features.first().getIn(ASSIGNEE_PATH as any, '');

          const hasMultipleValues =
            features.size > 1 &&
            features.some((f) => !!f!.getIn(ASSIGNEE_PATH as any)) &&
            !features.every((f) => f!.getIn(ASSIGNEE_PATH as any) === firstFeatureValue);

          // We set the 'Multiple assignees' value for multi-location rows with
          // multiple sub-row values in the accessor callback instead of the
          // Cell callback so that they sort to the top/bottom of the table.
          return hasMultipleValues ? 'Multiple assignees' : firstFeatureValue;
        },
      },
      makeDateRangeColumn({
        id: 'reportingDueDate',
        appProperty: CONSTANTS.APP_PROPERTY_REPORTING_DUE_DATE,
      }),
    ] as Column<PropertyTableRow>[];
  }, [
    imageryByFeatureId,
    userNoteCountsByFeatureId,
    alertNoteCountsByFeatureId,
    organization,
    organizationUsers,
    areaUnit,
    year,
    allowSelection,
    projectId,
    featureCollectionId,
    isHiddenImageryShownAsAvailable,
    showImageryPurchaseDrawer,
    tagSettings,
    batchPatchFeature,
    openCustomizeTagsModal,
    setYear,
    years,
    featureCollection,
    alertPolicies,
  ]);

  // Primary key is a comma-delimited string of sorted feature IDs, allowing for
  // unique IDs across multi-location grouped features rows and single feature
  // sub-rows, which all share the same non-unique feature name.
  const getRowId = React.useCallback(
    (row: PropertyTableRow) =>
      row.features
        .map((feature) => feature!.get('id'))
        .sort()
        .join(','),
    []
  );

  const data = React.useMemo<PropertyTableRow[]>(
    () =>
      groupedFeatures.map((features) => ({
        features,

        // Only set subRows if there are multiple features associated with the
        // row. This allows us to use the row's `canExpand` boolean property
        // directly.
        subRows:
          features.size > 1
            ? features
                // Sort sub-rows alphabetically by part name.
                .sort((a, b) => {
                  const partA = getMultiFeaturePropertyParts(features, a).find((p) => p.isActive)!;
                  const partB = getMultiFeaturePropertyParts(features, b).find((p) => p.isActive)!;
                  return sortMultiFeaturePropertyPartsCallback(partA, partB);
                })
                .map((feature) => ({features: I.List([feature!])}))
                .toArray()
            : undefined,
      })),
    [groupedFeatures]
  );

  // For some reason, `initialState` type is not unioned with `UseSortByState` like it is with
  // `UseFiltersState` and `UseRowSelectState`.

  const initialState: Partial<TableState<I.ImmutableListOf<ApiFeature>>> &
    UseSortByState<I.ImmutableListOf<ApiFeature>> = {
    filters: savedFilterState,
    selectedRowIds: savedSelectedRowIds,
    sortBy: savedSortState, // the TableHeader will set a default sort of "name" if we can't infer one from the location
    ...(isExpandedForTest
      ? {
          expanded: data
            .filter((datum) => datum.subRows?.length)
            .reduce((acc, datum) => ({...acc, [getRowId(datum)]: true}), {}),
        }
      : {}),
  };

  return useTable(
    {
      columns,
      data,
      getRowId,
      filterTypes: FILTER_TYPES,
      autoResetFilters: false,
      autoResetSelectedRows: false,
      initialState,
      autoResetExpanded: false,
    },
    useFilters,
    useFiltersPatch,
    useSortBy,
    useRowSelect,
    useExpanded
  );
}

function renderImageDetails({date, sourceId}: ImageDetails) {
  const sourceDetails =
    sourceId === null
      ? null
      : featureUtils.getSourceDetails(sourceId as featureUtils.SourceDetailsId);

  return (
    <>
      {date}
      {sourceDetails?.resolution !== undefined && `\xa0(${sourceDetails.resolution}m)`}
    </>
  );
}

export const HighResImageryCell: React.FunctionComponent<
  React.PropsWithChildren<{
    cell: {value: ImageryStatus};
    isHiddenImageryShownAsAvailable: boolean;
    openPopoverForTest?: boolean;
    showImageryPurchaseDrawer: () => void;
  }>
> = ({cell, isHiddenImageryShownAsAvailable, openPopoverForTest, showImageryPurchaseDrawer}) => {
  const {available: availableDates, purchased: purchasedDates, hidden: hiddenDates} = cell.value;

  let availableCount = availableDates.length;

  if (isHiddenImageryShownAsAvailable) {
    availableCount += hiddenDates.length;
  }

  if (availableCount === 0 && purchasedDates.length === 0) {
    return null;
  }

  const target = (
    <div
      style={{textDecoration: 'underline', textDecorationStyle: 'dotted'}}
      onClick={(ev) => {
        ev.stopPropagation();
        showImageryPurchaseDrawer();
      }}
    >
      <div>
        {/* TODO(fiona): show fraction of properties ordered if multi-part */}
        {purchasedDates.length > 0 && purchasedDates[0].date}
        {purchasedDates.length > 1 && `, …`}
      </div>

      {/* We show hidden and available counts together here (if "hidden" is being shown), but they'll still
      be broken out separately in the popover.

      For visual clarity, we don't show the "available" count if we had ordered dates.
      */}
      {purchasedDates.length === 0 && availableCount > 0 && (
        <div style={{fontStyle: 'italic'}}>{availableCount} available</div>
      )}
    </div>
  );

  return (
    <>
      {/* along with usePortal, needed to keep the popover from rendering too low */}
      <div style={{position: 'relative'}}>
        <B.Popover
          interactionKind={B.PopoverInteractionKind.HOVER}
          position={B.Position.RIGHT}
          isOpen={openPopoverForTest}
          content={
            <div className={cs.imageryPopoverContent} onClick={(ev) => ev.stopPropagation()}>
              {purchasedDates.length > 0 && (
                <>
                  <h4 className={cs.imageryPopoverHeading}>Ordered</h4>

                  <ul className={cs.imageryDateList}>
                    {purchasedDates.map((details) => (
                      <li key={`${details.date}-${details.sourceId}`}>
                        {renderImageDetails(details)}
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {availableDates.length > 0 && (
                <>
                  <h4 className={cs.imageryPopoverHeading}>Available</h4>

                  <ul className={cs.imageryDateList}>
                    {availableDates.map((details) => (
                      <li key={`${details.date}-${details.sourceId}`}>
                        {renderImageDetails(details)}
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {hiddenDates.length > 0 && (
                <>
                  <h4 className={cs.imageryPopoverHeading}>Hidden</h4>

                  <ul className={cs.imageryDateList}>
                    {hiddenDates.map((details) => (
                      <li key={`${details.date}-${details.sourceId}`}>
                        {renderImageDetails(details)}
                      </li>
                    ))}
                  </ul>
                </>
              )}

              <B.Button
                intent="primary"
                onClick={(ev) => {
                  ev.stopPropagation();
                  showImageryPurchaseDrawer();
                }}
                style={{whiteSpace: 'nowrap'}}
              >
                Show Imagery
              </B.Button>
            </div>
          }
        >
          {target}
        </B.Popover>
      </div>
    </>
  );
};

const SORTABLE_FIELDS = ['name', 'assignee', 'area', 'reportingDueDate'];
export const TableControls: React.FunctionComponent<
  React.PropsWithChildren<{
    table: TableInstance<PropertyTableRow>;
    featureCollection: I.ImmutableOf<ApiFeatureCollection>;
    project: I.ImmutableOf<ApiProject>;
    addProperties: () => void;
    updateProperties: () => void;
    profile: I.ImmutableOf<ApiOrganizationUser>;
  }>
> = ({table, featureCollection, project, addProperties, updateProperties, profile}) => {
  // Exclude the `selection` and `imagery` columns from the list of sortable column options.
  const columns = table.columns.filter((col) => SORTABLE_FIELDS.includes(col.id));
  const nameColumn = columns.find((col) => col.id === 'name')!;

  let sortColumn = columns.find((col) => col.isSorted)!;
  if (!sortColumn) {
    sortColumn = nameColumn;
  }
  const {id: sortColumnId, isSortedDesc} = sortColumn;

  const [isSelectLayersModalOpen, setIsSelectLayersModalOpen] = React.useState<boolean>(false);

  return (
    <div className={cs.tableControls}>
      <B.Popover
        position={B.Position.BOTTOM_RIGHT}
        usePortal={false}
        content={
          <B.Menu>
            <B.MenuItem
              id="asc"
              type="direction"
              text={'Ascending'}
              icon={isSortedDesc ? 'blank' : 'small-tick'}
              onClick={() => sortColumn.toggleSortBy(false, false)}
            />
            <B.MenuItem
              id="desc"
              type="direction"
              text={'Descending'}
              icon={isSortedDesc ? 'small-tick' : 'blank'}
              onClick={() => sortColumn.toggleSortBy(true, false)}
            />

            <B.MenuDivider />

            {columns.map((col) => (
              <B.MenuItem
                key={col.id}
                id={col.id}
                type="column"
                text={col.Header?.toString()}
                icon={col.id === sortColumnId ? 'small-tick' : 'blank'}
                onClick={() => col.toggleSortBy(!!isSortedDesc, false)}
              />
            ))}
          </B.Menu>
        }
      >
        <B.Button outlined icon={isSortedDesc ? 'sort-desc' : 'sort-asc'} />
      </B.Popover>
      <SearchBoxFilter setFilter={table.setFilter} column={nameColumn} />
      {profile.get('role') !== 'readonly' && (
        <>
          <B.Button
            outlined
            intent={B.Intent.PRIMARY}
            icon={'layers'}
            text={'Select datasets'}
            onClick={() => setIsSelectLayersModalOpen(true)}
          />
          <B.Button
            outlined
            intent={B.Intent.PRIMARY}
            icon={'plus'}
            text={'Add properties'}
            onClick={() => addProperties()}
          />
          <B.Button
            outlined
            intent={B.Intent.PRIMARY}
            icon={'edit'}
            text={'Update properties'}
            onClick={() => updateProperties()}
          />
        </>
      )}
      <PortfolioLayersSelector
        project={project}
        featureCollection={featureCollection}
        isOpen={isSelectLayersModalOpen}
        onClose={() => setIsSelectLayersModalOpen(false)}
      />
    </div>
  );
};

export const TableCellHeader: React.FunctionComponent<
  React.PropsWithChildren<{
    column: ColumnInstance<PropertyTableRow>;
    table: TableInstance<PropertyTableRow>;
  }>
> = ({column, table}) => {
  const filterIsActive = !!table.state.filters.find((f) => f.id === column.id);
  return (
    <th key={column.id} className={classnames(column.className, cs.headerCell)}>
      <h3>
        {column.render('Header')}
        {column.loading && <MisleadingHeaderProgressBar />}
      </h3>

      {column.filter && (
        <div className={classnames(cs.filterWrapper, {[cs.activeFilter]: filterIsActive})}>
          {column.render('Filter')}
        </div>
      )}
    </th>
  );
};

export const TableHeader: React.FunctionComponent<
  React.PropsWithChildren<{
    table: TableInstance<PropertyTableRow>;
  }>
> = ({table}) => {
  return (
    <tr className={cs.columnHeaders}>
      {table.columns.map((column) => (
        <TableCellHeader column={column} key={column.id} table={table} />
      ))}
    </tr>
  );
};

/**
 * Makes a progress bar that animates from 0 -> 0.8, then sits and spins until
 * it's unmounted.
 *
 * Used to show that we are loading a column's values, even if we're shady about
 * the actual amount of progress being made.
 */
const MisleadingHeaderProgressBar: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const [value, setValue] = React.useState(0);

  // The progress bar has a CSS transition that will smoothly animate to this
  // value, but for that to work we need to render with a value of 0 first.
  React.useEffect(() => {
    setValue(0.8);
  }, []);

  return <B.ProgressBar value={value} className={cs.columnHeaderLoading} />;
};

export const PropertyRow: React.FunctionComponent<
  React.PropsWithChildren<{
    data: {
      organizationId: string;
      projectId: string;
      featureCollectionId: number;
      allowSelection: boolean;
      table: TableInstance<PropertyTableRow>;
      onRowFocus: (row: Row<PropertyTableRow>) => void;
      onRowBlur: (row: Row<PropertyTableRow>) => void;
    };
    virtualRow: VirtualItem<Element>;
  }>
> = ({
  data: {table, organizationId, projectId, allowSelection, onRowFocus, onRowBlur},
  virtualRow,
}) => {
  const row = table.rows[virtualRow.index];
  table.prepareRow(row);

  const elRef = React.useRef<HTMLDivElement>(null);

  const navigateToFeature = React.useCallback(() => {
    changeSelection({
      organizationId,
      projectId,
      featureIds: row.original.features.map((f) => f!.getIn(['properties', 'lensId'])!).toSet(),
    });
  }, [organizationId, projectId, row.original]);

  const onKeyDown = React.useCallback(
    (ev: React.KeyboardEvent<HTMLDivElement>) => {
      if (ev.key === 'Enter') {
        navigateToFeature();
      } else if (ev.key === ' ') {
        if (allowSelection) {
          row.toggleRowSelected();
        }
        // Keeps us from scrolling down (in all cases, for consistency)
        ev.preventDefault();
      } else if (ev.key === 'ArrowDown') {
        if (elRef.current && elRef.current.nextElementSibling) {
          (elRef.current.nextElementSibling as HTMLElement).focus();
          ev.preventDefault();
        }
      } else if (ev.key === 'ArrowUp') {
        if (elRef.current && elRef.current.previousElementSibling) {
          (elRef.current.previousElementSibling as HTMLElement).focus();
          ev.preventDefault();
        }
      }
    },
    [navigateToFeature, row, allowSelection]
  );

  const isSubRow = getIsSubRow(row);

  return (
    <tr
      {...row.getRowProps()}
      data-id={row.id}
      // cs.row is important because that's what SearchBoxFilter looks for to
      // focus on when the down arrow is pressed.
      className={classnames(cs.row, {
        [cs.selected]: row.isSelected,
        [cs.expanded]: row.isExpanded,
        [cs.subRow]: isSubRow,
      })}
      // Giving ourselves a tabIndex lets the user use keyboard navigation
      tabIndex={0}
      onKeyDown={onKeyDown}
      onFocus={() => onRowFocus(row)}
      onBlur={() => onRowBlur(row)}
      style={{
        position: 'absolute',
        transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
        width: '100%',
      }}
    >
      {row.cells.map((cell) => {
        return (
          // key is provided by getCellProps

          <td
            {...cell.getCellProps()}
            key={cell.getCellProps().key}
            className={classnames(cell.column.className, {
              [cs.cellSelectSubRow]: isSubRow && cell.column.id === 'selection',
              [cs.cellTitleSubRow]: isSubRow && cell.column.id === 'name',
            })}
          >
            {cell.render('Cell')}
          </td>
        );
      })}
    </tr>
  );
};

/**
 * Determines what imagery is available and purchased for the given features and
 * year. Used by the "High-Res" column.
 *
 * The "features" list is expected to be all the features in a multi-part
 * property, or just a list of one feature for a single-feature property.
 */
function imageryAccessor(
  imageryByFeatureId: ImageryNotificationsByFeatureId | null,
  year: number,
  featureCollection: I.ImmutableOf<ApiFeatureCollection>,
  row: PropertyTableRow
): ImageryStatus {
  const {features} = row;

  // Flattened list (but not de-duped) of available scene ImageDetails (based on
  // the Lens imagery overview API) for all of the requested features, with any
  // records corresponding to purchased scenes filtered out.
  const allAvailableImageryDetails: I.List<ImageDetails> = features
    .map((f): I.Iterable<unknown, ImageDetails> => {
      const system = f!.getIn(['properties', '__system']);
      const scenes = system?.getIn(['paidImagery', 'scenes']);

      return (
        imageryByFeatureId
          ?.get(f!.get('id'))
          // Filters out available dates from those that were purchased. We do
          // this at the feature level so that buying a scene in a
          // multi-feature property doesn't hide that scene from "available"
          // for other parts.
          ?.filter(
            (rec) =>
              !scenes?.find((s) => {
                const orderedSourceId = s!.get(0);
                const orderedSensingTime = s!.get(1);

                return (
                  orderedSourceId === rec!.get('source_id') &&
                  moment(orderedSensingTime).isSame(rec!.get('date'))
                );
              }) &&
              // Only consider scenes that are highResTruecolor
              featureCollectionUtils.sourceHasHighResTruecolor(
                featureCollection,
                rec!.get('source_id')
              )
          )
          .map((rec) => {
            const sourceDetails = featureUtils.getSourceDetails(
              rec!.get('source_id') as featureUtils.SourceDetailsId
            );
            return {
              date: rec!.get('date'),
              sourceId: rec!.get('source_id'),
              resolution: sourceDetails?.resolution,
              isHidden: featureUtils.isSourceDateHidden(
                f!,
                [rec!.get('source_id')],
                rec!.get('date')
              ),
            };
          }) ?? I.List()
      );
    })
    .flatten(true)
    .toList();

  // Flattened list of ImageDetails (but not deduped) for all purchased scenes
  // for the given features.
  const paidImageryDetails: I.List<ImageDetails> = features
    // for each feature, returns an array of its bought imagery dates/sources
    .map((f): I.Iterable<unknown, ImageDetails> => {
      const system = f!.getIn(['properties', '__system']);
      const paidImagery =
        (system && system.get('paidImagery')) ||
        immutableUtils.toImmutableMap<I.ImmutableFields<SystemPaidImagery>>({
          scenes: I.List([]),
          limit: 0,
        });

      return paidImagery
        .get('scenes')
        .filter((s) =>
          // Only consider scenes that are highResTruecolor
          featureCollectionUtils.sourceHasHighResTruecolor(featureCollection, s!.get(0))
        )
        .map((s) => {
          const sourceDetails = featureUtils.getSourceDetails(
            s!.get(0) as featureUtils.SourceDetailsId
          );
          return {
            date: s!.get(1),
            sourceId: s!.get(0),
            resolution: sourceDetails?.resolution,
            isHidden: false,
          };
        });
    })
    // flattens down the multipart to a single array
    .flatten(true)
    .toList();

  const paidImagery = filterAndDedupeImageryDetails(paidImageryDetails, year);

  const availableImagery = filterAndDedupeImageryDetails(
    allAvailableImageryDetails.filter((d) => !d!.isHidden),
    year
  );

  const hiddenImagery = filterAndDedupeImageryDetails(
    allAvailableImageryDetails.filter((d) => !!d!.isHidden),
    year
  );

  const paidImagerySubMeter = paidImagery.filter((d) => !!d!.resolution);
  const availableSubMeterImagery = availableImagery.filter(
    (d) => !!d!.resolution && d!.resolution < 1
  );
  const hiddenSubMeterImagery = hiddenImagery.filter((d) => !!d!.resolution && d!.resolution < 1);

  return {
    available: availableImagery.toArray(),
    availableSubMeter: availableSubMeterImagery.toArray(),
    purchased: paidImagery.toArray(),
    purchasedSubMeter: paidImagerySubMeter.toArray(),
    hidden: hiddenImagery.toArray(),
    hiddenSubMeter: hiddenSubMeterImagery.toArray(),
  };
}

/**
 * Given a list of ImageDetails objects, filters them to the relevant year,
 * dedupes them, and returns them in ascending order by date.
 */
function filterAndDedupeImageryDetails(
  paidImageryDates: I.Iterable<number, ImageDetails>,
  year: number
) {
  return (
    paidImageryDates
      .map((details) =>
        // switch to immutable map so that we can use toOrderedSet to dedupe
        // them
        immutableUtils.toImmutableMap({
          dateMoment: moment(details!.date).startOf('day'),
          sourceId: details!.sourceId,
        })
      )
      // filters to just imagery in the year we care about
      .filter((details) => details!.get('dateMoment').get('year') === year)
      // order by date
      .sortBy((details) => details!.get('dateMoment').unix())
      // format to just mm/dd/yyyy
      .map((details) => {
        const sourceId = details!.get('sourceId');
        const sourceDetails = sourceId
          ? featureUtils.getSourceDetails(sourceId as featureUtils.SourceDetailsId)
          : null;
        return immutableUtils.toImmutableMap<ImageDetails>({
          date: details!.get('dateMoment').format('l'),
          sourceId: sourceId,
          resolution: sourceDetails?.resolution,
          // paid imagery is never hidden
          isHidden: false,
        });
      })
      .toOrderedSet()
      .map((details): ImageDetails => details!.toJS())
  );
}

export const SelectedRowActions: React.FunctionComponent<
  React.PropsWithChildren<{
    project: I.ImmutableOf<ApiProject>;
    role: ApiOrganizationUser['role'];
    organizationUsers: ApiOrganizationUser[];
    organization: I.ImmutableOf<ApiOrganization>;
    userNoteCountsByFeatureId: hookUtils.StatusMaybe<NoteCountsByFeatureId>;
    alertNoteCountsByFeatureId: hookUtils.StatusMaybe<ApiAlertCountsByFeatureId>;
    year: number;
    rows: Row<PropertyTableRow>[];
    clearSelection: () => void;
    batchPatchFeature: FeaturesActions['batchPatchFeature'];
    batchArchiveFeatures: FeaturesActions['batchArchiveFeatures'];
    moveFeatures: FeaturesActions['moveFeatures'];
    selectedFeatureCollection: I.ImmutableOf<ApiFeatureCollection>;
    openCustomizeTagsModal: () => void;
    setIsUpdatingFeatures: React.Dispatch<React.SetStateAction<boolean>>;
    openTaskImageryModal?: () => void;
  }>
> = ({
  project,
  role,
  organizationUsers,
  organization,
  userNoteCountsByFeatureId,
  alertNoteCountsByFeatureId,
  year,
  rows,
  batchPatchFeature,
  batchArchiveFeatures,
  moveFeatures,
  clearSelection,
  selectedFeatureCollection,
  openCustomizeTagsModal,
  setIsUpdatingFeatures,
  openTaskImageryModal,
}) => {
  const areaUnit = getAreaUnit(organization);

  const selectedPropertyRows = getSelectedPropertyRows(rows);

  const selectedFeatures = React.useMemo(
    () =>
      selectedPropertyRows.reduce((accum, row) => {
        const selectedPropertyFlatRows = getSelectedPropertyFlatRows(row);

        const selectedPropertyFeatures = selectedPropertyFlatRows.map((row) =>
          row.original.features.first()
        );

        return accum.concat(selectedPropertyFeatures).toList();
      }, I.List<I.ImmutableOf<ApiFeature>>([])),
    [selectedPropertyRows]
  );

  const selectedArea = React.useMemo(
    () =>
      conversionUtils.numberWithCommas(
        featureUtils.featureM2ToArea(
          selectedFeatures.reduce((n, f) => n! + featureUtils.getFeatureAreaM2(f!), 0),
          {unit: areaUnit}
        )
      ),
    [selectedFeatures, areaUnit]
  );

  const {
    EXPORT_ID,
    EXPORT_NAME,
    EXPORT_LOCATION_NAME,
    EXPORT_TAGS,
    EXPORT_AREA,
    EXPORT_NOTES,
    EXPORT_ALERTS,
    EXPORT_ASSIGNEE,
    EXPORT_DUE_DATE,
  } = FeatureAttributeExport;
  const exportCSV = () => {
    const tagSettings = tagUtils.getTagSettings(
      selectedFeatureCollection,
      tagUtils.TAG_KIND.FEATURE
    );

    const OMITTED_ATTRIBUTES = [
      // Omit system attributes
      ...FeatureAttributes.internalIdentifiers,
      ...FeatureAttributes.systemMetadata,
      ...FeatureAttributes.hiddenProperties,
      // Omit any attributes matching leading columns, if they happend to have been uploaded with them
      // We do this so that they don't clobber data in each row when assembling the data.
      EXPORT_ID,
      EXPORT_NAME,
      EXPORT_LOCATION_NAME,
      EXPORT_TAGS,
      EXPORT_AREA[areaUnit],
      EXPORT_ASSIGNEE,
      EXPORT_DUE_DATE,
    ];

    const pojoFeatures = selectedFeatures.toArray().map((f) => f.toJS());

    // Reduce selected feature's properties to a single, combined attribute list, omitting system attributes and attributes matching existing csv columns.
    const extraAttributes: string[] = Object.keys(
      featureUtils.moveFieldsToTop(
        pojoFeatures
          .map((f) => Object.keys(f.properties) || [])
          .reduce<Record<string, 1>>((keys: Record<string, 1>, n: string[]) => {
            n?.forEach((e) => (keys[e] = 1));
            return keys;
          }, {}),
        FeatureAttributes.identifiers
      )
    ).filter((e) => !OMITTED_ATTRIBUTES.includes(e));

    extraAttributes.splice(0, 3);

    const csvExporter = new ExportToCsv({
      showLabels: true,
      useBom: false,
      filename: `${project.get('name').replace(/\s+/g, '_')}_properties`,
      headers: [
        EXPORT_ID,
        EXPORT_NAME,
        EXPORT_LOCATION_NAME,
        EXPORT_TAGS,
        EXPORT_AREA[areaUnit],
        `${EXPORT_NOTES} ${year}`,
        EXPORT_ALERTS,
        EXPORT_ASSIGNEE,
        EXPORT_DUE_DATE,
        ...extraAttributes,
      ],
    });

    const data = pojoFeatures.map((f) => {
      const tagIds = tagUtils.getFeatureTagIds(f);
      const activeTags = tagSettings
        .filter((t) => tagIds.includes(t!.get('id')))
        .toList()
        .map((t) => t!.get('text').replace(/,/g, ''))
        .toJS()
        .join(',');
      const noteCounts =
        (userNoteCountsByFeatureId.status === 'some' &&
          userNoteCountsByFeatureId.value.getIn([f.id, year])) ||
        0;
      const alertCounts =
        (alertNoteCountsByFeatureId.status === 'some' &&
          alertNoteCountsByFeatureId.value[f.id]?.['all']) ||
        0;

      // Note: These objects are order sensitive, so attributes at the top will appear first in the row.
      return {
        [EXPORT_ID]: f.properties.lensId,
        [EXPORT_NAME]: f.properties.name,
        [EXPORT_LOCATION_NAME]: f.properties.multiFeaturePartName ?? '',
        [EXPORT_TAGS]: activeTags,
        [EXPORT_AREA[areaUnit]]: featureUtils.featureM2ToArea(featureUtils.getFeatureAreaM2(f), {
          unit: areaUnit,
        }),
        [`${EXPORT_NOTES} ${year}`]: noteCounts,
        [EXPORT_ALERTS]: alertCounts,
        [EXPORT_ASSIGNEE]: featureUtils.getIn(
          f,
          ['properties', CONSTANTS.APP_PROPERTIES_KEY, CONSTANTS.APP_PROPERTY_ASSIGNEE_EMAIL].join(
            '.'
          ),
          ''
        ),
        [EXPORT_DUE_DATE]: featureUtils.getIn(
          f,
          [
            'properties',
            CONSTANTS.APP_PROPERTIES_KEY,
            CONSTANTS.APP_PROPERTY_REPORTING_DUE_DATE,
          ].join('.'),
          ''
        ),
        ...Object.fromEntries(
          extraAttributes.map((attribute) => [attribute, f.properties[attribute] ?? ''])
        ),
      };
    });

    csvExporter.generateCsv(data);
  };

  const exportShapefile = React.useCallback(() => {
    const fileName = `${project.get('name').replace(/\s+/g, '_')}_properties`;
    shpwrite.download(
      geoJsonUtils.featureCollection(
        selectedFeatures
          .map((f) => {
            const id = f!.getIn(['properties', 'lensId']);
            const featureProperties = f!.getIn(['properties']).toJS();
            const omittedProperties = [
              ...FeatureAttributes.hiddenProperties,
              ...FeatureAttributes.internalIdentifiers,
              ...FeatureAttributes.systemMetadata,
            ];
            const exportedProperties = featureUtils.moveFieldsToTop(
              Object.fromEntries(
                Object.entries(featureProperties).filter(([attribute]) => {
                  // Omit double underscored attributes. These are likely system metadata.
                  return !(
                    attribute.startsWith('__') ||
                    attribute.startsWith('_') ||
                    omittedProperties.includes(attribute)
                  );
                })
              ),
              FeatureAttributes.identifiers
            );

            // There is a bug in the shp-write library where when trying to export a FeatureCollection
            // containing Polygons and MultiPolygons, only the MultiPolygons are included in the
            // exported shapefile. https://github.com/mapbox/shp-write/issues/121
            // So we convert Polygons to one part MultiPolygons
            let geometry = f!.get('geometry').toJS();
            if (geometry.type === 'Polygon') {
              geometry = {...geometry, type: 'MultiPolygon', coordinates: [geometry.coordinates]};
            }

            return geoJsonUtils.feature(geometry, exportedProperties, {id});
          })
          .toArray()
      ),
      {
        filename: fileName,
        folder: fileName,
        types: {
          polygon: fileName,
        },
        compression: 'STORE',
        outputType: 'blob',
      }
    );
  }, [project, selectedFeatures]);

  const archiveSelectedFeatures = async () => {
    const multiple = selectedFeatures.size > 1;
    const message = `Are you sure you want to delete ${
      multiple ? 'these properties' : 'this property'
    }? ${
      multiple ? 'They' : 'It'
    } will no longer be visible in Lens, so we recommend saving any reports prior to deleting.`;

    if (confirm(message)) {
      setIsUpdatingFeatures(true);
      await batchArchiveFeatures({
        featureCollectionId: selectedFeatureCollection.get('id'),
        features: selectedFeatures,
      });
      recordEvent('Archived features', {
        featureCollectionId: selectedFeatureCollection.get('id'),
        featureCount: selectedFeatures.size,
      });
      setIsUpdatingFeatures(false);
    }
  };

  const onClickDeactivateAllAlerts = async (alertPolicies) => {
    //create deactivate patch for all selected features for all Upstream alerts
    const featurePatch = I.fromJS({
      properties: {
        [CONSTANTS.APP_PROPERTIES_KEY]: {
          [CONSTANTS.ALERT_VEGETATION_DROP_ENROLLMENT_KEY]: false,
          [CONSTANTS.ALERT_OWNERSHIP_CHANGE_ENROLLMENT_KEY]: false,
        },
      },
    });
    //bulk unenroll any custom alerts with at least one enrolled feature
    await Promise.all(
      alertPolicies.map((alertPolicy) =>
        unenrollFeaturesInAlertPolicy(
          alertPolicy,
          selectedFeatures.toJS().map((feature) => feature.id)
        )
      )
    );

    //run feature patch for Upstream alerts
    batchPatchFeature({
      featureCollectionId: selectedFeatureCollection.get('id'),
      features: selectedFeatures,
      changes: I.fromJS(featurePatch),
    });
  };

  const onClickSingleUpstreamAlert = (id) => {
    let featurePatch: I.MergesInto<I.ImmutableOf<ApiFeature>> = {};
    // if we select a single Upstream alert, enroll selected features in that alert
    // unless they're all already enrolled, in which case unenroll them
    if (enrollmentStatusByKey[id] !== 'all') {
      featurePatch = featureUtils.makeAlertEnrollmentAppPropertyPatch(id, true);
    } else {
      featurePatch = featureUtils.makeAlertEnrollmentAppPropertyPatch(id, false);
    }
    batchPatchFeature({
      featureCollectionId: selectedFeatureCollection.get('id'),
      features: selectedFeatures,
      changes: I.fromJS(featurePatch),
    });
  };

  const onClickSingleCustomAlert = (selectedCustomAlert) => {
    if (
      featureUtils.enrollmentStatusForCustomAlert(selectedFeatures, selectedCustomAlert) !== 'all'
    ) {
      enrollFeaturesInAlertPolicy(
        selectedCustomAlert,
        selectedFeatures.toJS().map((feature) => feature.id)
      );
    } else {
      unenrollFeaturesInAlertPolicy(
        selectedCustomAlert,
        selectedFeatures.toJS().map((feature) => feature.id)
      );
    }
  };

  const isOwner = role === CONSTANTS.USER_ROLE_OWNER;
  const vegetationDropEnrollment = featureUtils.enrollmentStatusForUpstreamAlert(
    selectedFeatures,
    CONSTANTS.ALERT_VEGETATION_DROP_ENROLLMENT_KEY
  );
  const ownershipChangeEnrollment = featureUtils.enrollmentStatusForUpstreamAlert(
    selectedFeatures,
    CONSTANTS.ALERT_OWNERSHIP_CHANGE_ENROLLMENT_KEY
  );

  const enrollmentStatusByKey = {
    [CONSTANTS.ALERT_VEGETATION_DROP_ENROLLMENT_KEY]: vegetationDropEnrollment,
    [CONSTANTS.ALERT_OWNERSHIP_CHANGE_ENROLLMENT_KEY]: ownershipChangeEnrollment,
  };

  const {
    alertPolicies,
    actions: {enrollFeaturesInAlertPolicy, unenrollFeaturesInAlertPolicy},
  } = useAlertPolicies();

  return (
    <div className={cs.selectedRowActions}>
      <div className={cs.selectedRowSummary}>
        <strong>
          {`${selectedPropertyRows.some((row) => getIsSomeSelected(row)) ? 'Locations from ' : ''}${
            selectedPropertyRows.length === 1
              ? '1 property selected'
              : `${selectedPropertyRows.length} properties selected`
          }`}
        </strong>{' '}
        ({selectedArea} {areaUnit === 'areaHectare' ? 'hectares' : 'acres'}) –{' '}
        <a
          onClick={(ev) => {
            ev.preventDefault();
            clearSelection();
          }}
        >
          clear selection
        </a>
      </div>

      <div className={cs.selectedRowButtons}>
        <EditTagsMenu
          featureCollectionId={selectedFeatureCollection.get('id')}
          tagSettings={tagUtils.getTagSettings(
            selectedFeatureCollection,
            tagUtils.TAG_KIND.FEATURE
          )}
          tagStatusMaps={tagUtils.getFeaturesTagStatusMaps(selectedFeatures)}
          onChange={(tagStatusMap) =>
            batchPatchFeature({
              featureCollectionId: selectedFeatureCollection.get('id'),
              features: selectedFeatures,
              changes: featureUtils.tagStatusMapToPropertiesChanges(tagStatusMap),
            })
          }
          openCustomizeTagsModal={role === 'owner' ? openCustomizeTagsModal : null}
          kind={tagUtils.TAG_KIND.FEATURE}
        >
          <B.Button icon="tag" rightIcon="caret-down">
            Tags
          </B.Button>
        </EditTagsMenu>

        {featureFlags.LOOKOUTS(organization) && (
          <B.Popover
            position={B.Position.BOTTOM_RIGHT}
            modifiers={{arrow: {enabled: false}}}
            content={
              <List
                className={cs.actionMenu}
                items={[
                  {
                    id: 'deactivate-alerts',
                    text: 'Unenroll selected properties',
                  },
                  {id: 'sep', text: '', isSeparator: true},
                  ...getAlertListItems(
                    vegetationDropEnrollment,
                    ownershipChangeEnrollment,
                    selectedFeatureCollection,
                    alertPolicies,
                    selectedFeatures
                  ),
                ]}
                onClickItem={async ({id}) => {
                  const selectedCustomAlert = alertPolicies.find(
                    (alertPolicy) => JSON.stringify(alertPolicy.id) === id
                  );
                  const alertsWithAnyEnrolledFeatures = alertPolicies.filter(
                    (alertPolicy) =>
                      featureUtils.enrollmentStatusForCustomAlert(selectedFeatures, alertPolicy) !==
                      'none'
                  );
                  if (
                    id === 'deactivate-alerts' &&
                    confirm(
                      'Are you sure you want to deactivate all alerts for selected properties?'
                    )
                  ) {
                    await onClickDeactivateAllAlerts(alertsWithAnyEnrolledFeatures);
                  } else if (featureUtils.isAlertEnrollmentKey(id)) {
                    await onClickSingleUpstreamAlert(id);
                  } else if (selectedCustomAlert) {
                    await onClickSingleCustomAlert(selectedCustomAlert);
                  }
                }}
              />
            }
          >
            <B.Button icon={<LookoutIcon />} rightIcon="caret-down">
              {'Lookouts'}
            </B.Button>
          </B.Popover>
        )}

        <B.Popover
          position={B.Position.BOTTOM_RIGHT}
          modifiers={{arrow: {enabled: false}}}
          content={
            <List
              className={cs.actionMenu}
              items={[
                {id: '', text: 'Remove Assignment'},
                {id: 'sep', text: '', isSeparator: true},
                ...makeUserListItems(organizationUsers),
              ]}
              onClickItem={({id}) => {
                if (
                  id === '' &&
                  !confirm('Are you sure you want to clear assignees for selected properties?')
                ) {
                  return;
                }

                const properties: Partial<ApiFeatureCommonProperties> = {
                  [CONSTANTS.APP_PROPERTIES_KEY]: {
                    [CONSTANTS.APP_PROPERTY_ASSIGNEE_EMAIL]: id,
                  },
                };

                batchPatchFeature({
                  featureCollectionId: selectedFeatureCollection.get('id'),
                  features: selectedFeatures,
                  changes: I.fromJS({properties}),
                });

                recordEvent(id ? 'Set assignee' : 'Unset assignee', {
                  featureCollectionId: selectedFeatureCollection.get('id'),
                  featureCount: selectedFeatures.size,
                });
              }}
            />
          }
        >
          <B.Button icon="person" rightIcon="caret-down">
            Assignee
          </B.Button>
        </B.Popover>

        <B.Popover
          position={B.Position.BOTTOM_RIGHT}
          modifiers={{arrow: {enabled: false}}}
          captureDismiss={true}
          content={
            <DatePicker
              showActionsBar={true}
              maxDate={moment().add(5, 'years').toDate()}
              onChange={(date: Date) => {
                // The `date` argument is in the browser's local timezone, and the
                // `moment` library preserves the timezone (see
                // `date.toISOString()` and `moment(date).toISOString()`).
                const properties: Partial<ApiFeatureCommonProperties> = {
                  [CONSTANTS.APP_PROPERTIES_KEY]: {
                    [CONSTANTS.APP_PROPERTY_REPORTING_DUE_DATE]: date
                      ? moment(date).format('YYYY-MM-DD')
                      : '',
                  },
                };

                batchPatchFeature({
                  featureCollectionId: selectedFeatureCollection.get('id'),
                  features: selectedFeatures,
                  changes: I.fromJS({properties}),
                });

                recordEvent(date ? 'Set report due date' : 'Unset report due date', {
                  featureCollectionId: selectedFeatureCollection.get('id'),
                  featureCount: selectedFeatures.size,
                });
              }}
            />
          }
        >
          <B.Button icon="calendar" rightIcon="caret-down">
            Due Date
          </B.Button>
        </B.Popover>

        {role !== 'readonly' && (
          <MovePropertiesMenu
            features={selectedFeatures}
            featureCollectionId={selectedFeatureCollection.get('id')}
            projectId={project.get('id')}
            moveFeatures={moveFeatures}
            setIsUpdatingFeatures={setIsUpdatingFeatures}
          >
            <B.Button icon="add-to-folder" rightIcon="caret-down">
              Move
            </B.Button>
          </MovePropertiesMenu>
        )}

        {isOwner && (
          <B.Button icon="trash" onClick={archiveSelectedFeatures}>
            Delete
          </B.Button>
        )}

        <B.Popover
          position={B.Position.BOTTOM_RIGHT}
          modifiers={{arrow: {enabled: false}}}
          captureDismiss={true}
          content={
            <B.Menu>
              <B.MenuItem text={`Export Shapefile`} onClick={exportShapefile} />
              <B.MenuItem text="Export CSV" onClick={exportCSV} />
              {openTaskImageryModal && (
                <React.Fragment>
                  <B.MenuDivider />
                  <B.MenuItem text="Estimate Tasking" onClick={openTaskImageryModal} />
                </React.Fragment>
              )}
            </B.Menu>
          }
        >
          <B.Button icon="more" />
        </B.Popover>
      </div>
    </div>
  );
};

function makeDateRangeColumn({id, appProperty}: {id: string; appProperty: AppProperty}) {
  return {
    id,
    Header: APP_PROPERTY_NAMES[appProperty],
    className: cs.cellDate,
    Filter: DateFilter,
    filter: makeGetDateRangeFilter(appProperty),
    accessor: ({features}: PropertyTableRow) => {
      const APP_PROPERTY_PATH = ['properties', CONSTANTS.APP_PROPERTIES_KEY, appProperty];

      const firstFeatureValue = features.first().getIn(APP_PROPERTY_PATH as any, '');

      const hasMultipleValues =
        features.size > 1 &&
        features.some((f) => !!f!.getIn(APP_PROPERTY_PATH as any)) &&
        !features.every((f) => f!.getIn(APP_PROPERTY_PATH as any) === firstFeatureValue);

      // We set the `Multiple dates` value for multi-location rows with multiple sub-row
      // values in the accessor callback instead of the Cell callback so that
      // they sort to the top/bottom of the table.
      return hasMultipleValues
        ? 'Multiple dates'
        : firstFeatureValue
          ? moment(firstFeatureValue).unix()
          : firstFeatureValue;
    },
    Cell: ({row, cell: {value}}) => {
      // Blank out if expanded since information is represented by sub-rows.
      if (row.isExpanded) {
        return null;
      }

      if (value === 'Multiple dates') {
        return <i>{value}</i>;
      }

      return value ? moment.unix(value).format('MM/DD/YYYY') : value;
    },
  };
}

function getAssigneeFilter(
  rows: Row<PropertyTableRow>[],
  _,
  filterValue: any
): Row<PropertyTableRow>[] {
  // If the filter value is `null`, show all rows.
  if (filterValue === null) {
    return rows;
  } else {
    return rows.filter((r) => {
      const flatRows = r.subRows?.length ? r.subRows : [r];

      const assignees = flatRows.map((r) => r.values.assignee);

      return (
        // If we are filtering for rows with an assignee, return rows where some
        // of its features have a truthy assignee.
        (filterValue === '*' && assignees.some((a) => a)) ||
        // If we are filtering for rows without an assignee, return rows where
        // some of its features have a falsey assignee.
        (filterValue === '' && assignees.some((a) => !a)) || // Otherwise, return the rows where some of its features have an
        // assignee email that matches the filter value.
        assignees.some((a) => a === filterValue)
      );
    });
  }
}

function makeGetDateRangeFilter(appProperty: AppProperty) {
  return function (rows: Row<PropertyTableRow>[], _, filterValue: any): Row<PropertyTableRow>[] {
    // If the filter value is `null`, show all rows.
    if (filterValue === null) {
      return rows;
    } else {
      return rows.filter((r) => {
        const flatRows = r.subRows?.length ? r.subRows : [r];

        const dates = flatRows.map((r) => r.values[appProperty]);

        return (
          // If we are filtering for rows with a date, return rows where some of
          // its features have a truthy date.
          (filterValue === '*' && dates.some((d) => d)) ||
          // If we are filtering for rows without a date, return rows where some
          // of its features have a falsey date.
          (filterValue === '' && dates.some((d) => !d)) || // Otherwise, return the rows where some of its features have a date
          // that between now and the filter value.
          dates.some((d) => {
            // The makeDateRangeColumn output accessor converts date values to
            // UNIX timestamps, so we need to create the moment using the `unix`
            // parse method.
            const date = moment.unix(d);

            const dateEnd = moment(date).endOf('day');
            const todayStart = moment().startOf('day');
            const filterDateEnd = moment()
              .add(filterValue - 1, 'days')
              .endOf('day');

            // [] for inclusivity.
            return dateEnd.isBetween(todayStart, filterDateEnd, null, '[]');
          })
        );
      });
    }
  };
}

/**
 * Filter a list of rows to those that are (1) at a depth of 0, meaning they
 * represent an entire property, and (2) are fully or partially (e.g., only some
 * sub-rows) selected.
 */
export function getSelectedPropertyRows(rows: Row<PropertyTableRow>[]) {
  return rows.filter((row) => row.depth === 0 && (row.isSelected || row.isSomeSelected));
}

/**
 * Given a row, return a flat list of rows. For single-location properties, this
 * is an array containing the original row. For multi-location properties, this
 * is an array of selected sub-rows.
 */
export function getSelectedPropertyFlatRows(row: Row<PropertyTableRow>) {
  // react-table sets the `row.isSomeSelected` property inconsistently, so we
  // use a wrapper function to attempt to identify indeterminate parent rows.
  const isSomeSelected = getIsSomeSelected(row);

  const selectedSubRows = row.subRows.filter((subRow) => subRow.isSelected === true);

  return isSomeSelected
    ? selectedSubRows
    : row.subRows?.length && row.isSelected
      ? row.subRows
      : [row];
}

/**
 * Find the parent row for a provided row. If the provided row does not have a
 * parent row, the same row is returned.
 */
function getParentRow(row: Row<PropertyTableRow>, rows: Row<PropertyTableRow>[]) {
  if (row.depth === 0) {
    return row;
  }

  // We know that there will be a parent row for every sub-row, so we cast as
  // Row<PropertyTableRow> to bypass the maybe undefined type checking.
  return rows.find((r) => r.depth === 0 && r.id.includes(row.id)) as Row<PropertyTableRow>;
}

/**
 * Returns true if the provided row is a sub-row of another.
 */
function getIsSubRow(row: Row<PropertyTableRow>) {
  return row.depth > 0;
}

/**
 * Returns true if the provided row is an expandable parent row for which only
 * some sub-rows are selected. Corrects for a react-table bug in which
 * `row.isSomeSelected` is inconsistently set by falling back to a manual check
 * of sub-row selection states.
 */
function getIsSomeSelected(row: Row<PropertyTableRow>) {
  if (!row.subRows?.length) {
    return false;
  }

  const selectedSubRows = row.subRows.filter((subRow) => subRow.isSelected === true);

  return (
    row.isSomeSelected ||
    (selectedSubRows.length > 0 && selectedSubRows.length < row.subRows.length)
  );
}
