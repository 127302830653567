import * as B from '@blueprintjs/core';
import 'app/styles/blueprintShim.scss';
import 'app/styles/global.css';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {Chart} from 'chart.js';
import {History} from 'history';
import React from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {Helmet} from 'react-helmet';

import './assets/images/lens-logo-favicon-outline.ico';
import './assets/images/lens-logo-favicon.ico';

import GlobalErrorBoundary from 'app/components/ErrorBoundaries';
import Loading from 'app/components/Loading';
import PushNotification, {PushNotificationProvider} from 'app/components/Notification';
import VersionUpdateMonitor from 'app/modules/Realtime/VersionUpdateMonitor';
import AuthProvider, {WithAuth} from 'app/providers/AuthProvider';
import OrgUsersProvider from 'app/providers/OrgUsersProvider';
import ProjectsProvider from 'app/providers/ProjectsProvider';
import FirebaseProvider from 'app/tools/Firebase';
import Hubspot from 'app/tools/Hubspot';
import {SentrySessionReplayListener, SentryUserListener} from 'app/tools/Sentry';

import {NavSidebarProvider} from './components/NavSidebar/NavSidebarProvider';
import AppRouter from './routes';
import Analytics from './tools/Analytics';

B.FocusStyleManager.onlyShowFocusOnTabs();

// We need to do this globally because you can’t set the font for a particular
// chart (you have to set it for the individual parts of the chart, which is
// annoying.)
//
// If you change this here, do it in preview.js as well for Storybook.
//
// TODO(fiona): This could be nicer.
Chart.defaults.font = {
  family: "'Lato', Helvetica, Arial, sans-serif",
  size: 14,
  lineHeight: 1,
  style: 'normal',
};

export interface Props {
  history: History;
}

class App extends React.Component<Props> {
  render() {
    const {history} = this.props;
    const queryClient = new QueryClient();

    return (
      <GlobalErrorBoundary>
        <Helmet>
          <title>Lens</title>
        </Helmet>
        <QueryClientProvider client={queryClient}>
          <DndProvider backend={HTML5Backend}>
            <FirebaseProvider>
              <B.HotkeysProvider dialogProps={{globalGroupName: 'Keyboard Shortcuts'}}>
                <NavSidebarProvider>
                  <PushNotificationProvider>
                    <VersionUpdateMonitor />
                    <AuthProvider renderLoading={() => <Loading />}>
                      <WithAuth>
                        {(auth) => {
                          const organizationId =
                            auth.status === 'logged in'
                              ? (auth.currentOrganization?.get('id') ?? null)
                              : null;

                          return (
                            <>
                              <PushNotification />

                              <SentryUserListener />
                              <SentrySessionReplayListener history={history} />
                              <Hubspot />
                              <Analytics />

                              <ProjectsProvider organizationId={organizationId}>
                                <OrgUsersProvider organizationId={organizationId}>
                                  <AppRouter history={history} />
                                </OrgUsersProvider>
                              </ProjectsProvider>
                            </>
                          );
                        }}
                      </WithAuth>
                    </AuthProvider>
                  </PushNotificationProvider>
                </NavSidebarProvider>
              </B.HotkeysProvider>
            </FirebaseProvider>
          </DndProvider>
        </QueryClientProvider>
      </GlobalErrorBoundary>
    );
  }
}

export default App;
