import {History} from 'history';
import React from 'react';

import {LoggedInUserActions} from 'app/providers/AuthProvider';
import * as C from 'app/src/utils/constants';
import {atLeastLensTier, useUserInfo} from 'app/utils/featureFlags';

import cs from './Dashboard.styl';
import AppNav from '../AppNav';
import {NavSidebarContainer} from '../NavSidebar';

type DashboardPageProps = {
  loggedInUserActions: LoggedInUserActions;
  history: History;
};

const Dashboard: React.FC<DashboardPageProps> = ({history, loggedInUserActions}) => {
  const [organization, profile] = useUserInfo();
  const orgName = organization.get('name');
  const isEnterprise = atLeastLensTier(C.LENS_TIER_ENTERPRISE, organization);

  return (
    <>
      <AppNav
        history={history}
        organization={organization}
        profile={profile}
        selectedProject={null}
        loggedInUserActions={loggedInUserActions}
      />
      <NavSidebarContainer history={history}>
        <div className={cs.dashboardContainer} data-testid="dashboard-container">
          <h1>
            Placeholder {!isEnterprise ? 'preview' : ''} dashboard for {orgName}
          </h1>
          <p>Under construction!</p>
        </div>
      </NavSidebarContainer>
    </>
  );
};

export default Dashboard;
