import {History} from 'history';
import * as I from 'immutable';
import React from 'react';
import {Redirect} from 'react-router';

import {OverlaysProvider} from 'app/components/Library/OverlaysProvider';
import {usePushNotification} from 'app/components/Notification';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {LoggedInUserActions} from 'app/providers/AuthProvider';
import featureFlags from 'app/utils/featureFlags';
import * as routeUtils from 'app/utils/routeUtils';

import ManageFeaturesOverlay from './ManageFeaturesOverlay';
import ManageFeaturesProvider from './ManageFeaturesProvider';
import ManageFeaturesSelectMode from './ManageFeaturesSelectMode';
import ManagePropertiesDraw from './ManagePropertiesDraw';
import ManagePropertiesSelectParcels from './ManagePropertiesSelectParcels';
import ManagePropertiesUpload from './ManagePropertiesUpload';

const MANAGE_PROPERTIES_MODES = ['upload', 'draw', 'parcel', 'select', 'overlay'];
export type ManagePropertiesMode = (typeof MANAGE_PROPERTIES_MODES)[number];

export function isManagePropertyModeOption(value: string): boolean {
  return MANAGE_PROPERTIES_MODES.includes(value);
}

export interface Props {
  history: History;
  profile: I.ImmutableOf<ApiOrganizationUser>;
  organization: I.MapAsRecord<I.ImmutableFields<ApiOrganization>>;
  loggedInUserActions: LoggedInUserActions;
  projectId?: string;
  mode: ManagePropertiesMode;
  firebaseToken: string | null;
}
const ManageFeatures: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  history,
  profile,
  organization,
  loggedInUserActions,
  projectId,
  mode,
  firebaseToken,
}) => {
  const orgIdPrefix = routeUtils.makeUuidPrefix(organization.get('id'));
  const pushNotification = usePushNotification();

  if (profile.get('role') == 'readonly') {
    pushNotification({
      message: 'You do not have authorization to manage properties for this organization.',
      autoHideDuration: 4000,
    });

    return <Redirect to={`/${orgIdPrefix}/projects`} />;
  }

  const renderManageFeaturesView = () => {
    switch (mode) {
      case 'draw':
        return (
          <ManagePropertiesDraw
            history={history}
            profile={profile}
            organization={organization}
            loggedInUserActions={loggedInUserActions}
            firebaseToken={firebaseToken}
          />
        );
      case 'parcel':
        if (featureFlags.PARCEL_DATA_IN_UPLOADER(organization, profile)) {
          return (
            <ManagePropertiesSelectParcels
              history={history}
              profile={profile}
              organization={organization}
              loggedInUserActions={loggedInUserActions}
              firebaseToken={firebaseToken}
            />
          );
        } else {
          return (
            <Redirect to={`/${orgIdPrefix}/admin/manageProperties/select/${projectId || ''}`} />
          );
        }
      case 'upload':
        return (
          <ManagePropertiesUpload
            history={history}
            profile={profile}
            organization={organization}
            loggedInUserActions={loggedInUserActions}
          />
        );
      case 'overlay':
        return (
          <OverlaysProvider organization={organization}>
            <ManageFeaturesOverlay
              history={history}
              profile={profile}
              organization={organization}
              loggedInUserActions={loggedInUserActions}
            />
          </OverlaysProvider>
        );
      case 'select':
        return (
          <ManageFeaturesSelectMode
            history={history}
            profile={profile}
            organization={organization}
            loggedInUserActions={loggedInUserActions}
            projectId={projectId || ''}
          />
        );
      default:
        <ManageFeaturesSelectMode
          history={history}
          profile={profile}
          organization={organization}
          loggedInUserActions={loggedInUserActions}
          projectId={projectId || ''}
        />;
    }
  };

  return (
    <ManageFeaturesProvider
      history={history}
      organization={organization}
      mode={mode}
      projectId={projectId}
    >
      {renderManageFeaturesView()}
    </ManageFeaturesProvider>
  );
};

export default ManageFeatures;
