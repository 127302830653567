import * as Sentry from '@sentry/react';
import {History} from 'history';
import queryString from 'query-string';
import React from 'react';
import {Redirect, Route, Router, Switch} from 'react-router-dom';
import {QueryParamProvider} from 'use-query-params';

import Dashboard from 'app/components/Dashboard';
import ErrorPages from 'app/components/Error';
import InternalTools from 'app/components/InternalTools/InternalTools';
import RequireRoleContainer from 'app/components/RequireRoleContainer';
import SignOut from 'app/components/SignOut/view';
import NewCustomerSignUpSuccess from 'app/components/SignUp/SignupSuccess';
import LensHome from 'app/pages/Home';
import ProjectDashboardSwitch from 'app/pages/ProjectDashboardSwitch';
import {WithAuth, WithLoggedInAuth, WithLoggedOutAuth} from 'app/providers/AuthProvider';
import {getApiRoute} from 'app/utils/apiUtils';
import {loginAppUrl} from 'app/utils/environmentUtils';
import featureFlags from 'app/utils/featureFlags';
import * as routeUtils from 'app/utils/routeUtils';
import ScrollOnRouteChange from 'app/utils/ScrollOnRouteChange';
import * as userUtils from 'app/utils/userUtils';

import GlobalSettings, {isGlobalSettingsView} from './components/GlobalSettings/GlobalSettings';
import PublicMap from './components/PublicMap';
import ManageFeatures, {
  ManagePropertiesMode,
  isManagePropertyModeOption,
} from './pages/ManageProperties';
import {OnboardingSlidesView} from './pages/OnboardingSlidesView';
import OrganizationSwitch from './pages/OrganizationSwitch';
import PropertyUpdate from './pages/PropertyUpdate';
import NewCustomerSignUpProviderWrapper from './pages/SignUp';
// Used to inform Sentry about :params in the URLs. See:
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#parameterized-transaction-names
const SentryRoute = Sentry.withSentryRouting(Route);

interface Props {
  history: History;
}

export default class AppRouter extends React.Component<Props> {
  render() {
    const {history} = this.props;

    // We favor using `render` over `component` below so that we get TypeScript
    // ensuring that we're passing all necessary props to the component.
    return (
      <Router history={history}>
        <QueryParamProvider ReactRouterRoute={SentryRoute}>
          <ScrollOnRouteChange />

          <Switch>
            <SentryRoute path="/error" render={() => <ErrorPages />} />

            <SentryRoute
              path="/signin"
              render={() => (
                <WithLoggedOutAuth>
                  {(_auth) => {
                    // We want to handle this in the login app from now on.
                    window.location.assign(loginAppUrl);
                    return <div />; // unused, need to return something to satisfy the type checker
                  }}
                </WithLoggedOutAuth>
              )}
            />

            <SentryRoute
              path="/signout"
              render={() => <WithAuth>{(auth) => <SignOut auth={auth} />}</WithAuth>}
            />

            <SentryRoute
              path="/signup"
              render={() => {
                return (
                  <Switch>
                    {/* User signing up for an account from link */}
                    <SentryRoute
                      path="/signup/:role/:key?"
                      render={({match: {params}}) => {
                        const {role, key} = params;
                        // We want to handle this in the login app from now on.
                        window.location.assign(`${loginAppUrl}/signup/${role}/${key}`);
                        return null;
                      }}
                    />
                    {/* Organization signing up for Lens */}
                    <SentryRoute
                      exact
                      render={({location}) => {
                        const params = queryString.parse(location.search, {arrayFormat: 'comma'});
                        const defaultFormState = routeUtils.parseSignUpQueryParams(params);
                        return (
                          <NewCustomerSignUpProviderWrapper initialFormState={defaultFormState} />
                        );
                      }}
                    />
                  </Switch>
                );
              }}
            />

            <SentryRoute path="/signupSuccess" render={() => <NewCustomerSignUpSuccess />} />

            <SentryRoute
              path="/oauth/sso"
              render={() => (
                <WithLoggedInAuth>
                  {(auth) => {
                    const params = queryString.parse(window.location.search);
                    params.apiToken = auth.firebaseToken;
                    const url = getApiRoute('oauth/authorize', params);
                    // Use window.location.href not <Redirect> since we're leaving this app
                    window.location.href = url;
                    return <div />; // unused, need to return something to satisfy the type checker
                  }}
                </WithLoggedInAuth>
              )}
            />

            <SentryRoute
              path="/discourse/sso"
              render={() => (
                <WithLoggedInAuth>
                  {(auth) => {
                    const params = queryString.parse(window.location.search);
                    params.apiToken = auth.firebaseToken;
                    const url = getApiRoute('user/discourse', params);
                    // Use window.location.href not <Redirect> since we're leaving this app
                    window.location.href = url;
                    return <div />; // unused, need to return something to satisfy the type checker
                  }}
                </WithLoggedInAuth>
              )}
            />

            <SentryRoute
              path="/sso/login"
              render={() => (
                <WithLoggedInAuth>
                  {(auth) => {
                    // Use window.location.href not <Redirect> since we're leaving this app
                    const url = getApiRoute('saml/login/process', {apiToken: auth.firebaseToken});
                    window.location.href = url;
                    return <div />; // unused, need to return something to satisfy the type checker
                  }}
                </WithLoggedInAuth>
              )}
            />

            <SentryRoute
              path="/forgotPassword"
              render={() => {
                // We want to handle this in the login app from now on.
                window.location.assign(`${loginAppUrl}/forgotPassword`);
                return null;
              }}
            />

            <SentryRoute
              path="/welcome"
              render={({location, history}) => {
                return (
                  <WithLoggedInAuth>
                    {(_auth, profile, {currentOrganization}, loggedInUserActions) => {
                      const redirectUrl = location.search.split('?')[1];
                      return (
                        <OnboardingSlidesView
                          redirectUrl={redirectUrl}
                          history={history}
                          organization={currentOrganization}
                          profile={profile}
                          loggedInUserActions={loggedInUserActions}
                        />
                      );
                    }}
                  </WithLoggedInAuth>
                );
              }}
            />

            <SentryRoute
              path="/projects"
              render={({location}) => {
                return (
                  <WithLoggedInAuth>
                    {(auth, _profile, {currentOrganization, profileOrganizations}) => {
                      if (
                        userUtils.shouldSeeOrgSwitcher(currentOrganization, profileOrganizations)
                      ) {
                        return <Redirect to="/organizations" />;
                      }
                      // This redirect handles legacy URLs that don't include the Org ID prefix
                      return (
                        <Redirect
                          to={`/${routeUtils.makeUuidPrefix(auth.currentOrganization!.get('id'))}${
                            location.pathname
                          }${location.hash || ''}${location.search || ''}`}
                        />
                      );
                    }}
                  </WithLoggedInAuth>
                );
              }}
            />

            <SentryRoute
              path="/:currentOrgIdPrefix/projects"
              render={({history, match}) => {
                const orgIdPrefix = match.params.currentOrgIdPrefix;
                return (
                  <routeUtils.CurrentOrgIdPrefix.Provider value={orgIdPrefix}>
                    <WithLoggedInAuth>
                      {(auth, profile, {currentOrganization}, loggedInUserActions) => {
                        return (
                          <Switch>
                            <SentryRoute
                              path="/:currentOrgIdPrefix/projects/:selectedProjectId/:page?/:featureIds?"
                              render={({match: {params}}) => {
                                const {selectedProjectId, featureIds} = params;
                                // NOTE: right now page is ignored. It is always "map" in the urls
                                // we construct, but regardless of the string we render the map view
                                // for the selected features
                                return (
                                  <ProjectDashboardSwitch
                                    history={history}
                                    profile={profile}
                                    organization={currentOrganization}
                                    loggedInUserActions={loggedInUserActions}
                                    firebaseToken={auth.firebaseToken}
                                    selectedProjectIdParam={selectedProjectId}
                                    selectedFeatureIdsParam={featureIds || null}
                                  />
                                );
                              }}
                            />

                            <SentryRoute
                              exact
                              render={({history}) => {
                                return (
                                  <LensHome
                                    history={history}
                                    organization={currentOrganization}
                                    profile={profile}
                                    loggedInUserActions={loggedInUserActions}
                                  />
                                );
                              }}
                            />
                          </Switch>
                        );
                      }}
                    </WithLoggedInAuth>
                  </routeUtils.CurrentOrgIdPrefix.Provider>
                );
              }}
            />

            <SentryRoute
              path="/organizations"
              render={({history}) => (
                <WithLoggedInAuth>
                  {(
                    _,
                    profile,
                    {currentOrganization, profileOrganizations},
                    loggedInUserActions
                  ) => {
                    return userUtils.shouldSeeOrgSwitcher(
                      currentOrganization,
                      profileOrganizations
                    ) ? (
                      <OrganizationSwitch
                        profile={profile}
                        history={history}
                        currentOrganization={currentOrganization}
                        profileOrganizations={profileOrganizations}
                        loggedInUserActions={loggedInUserActions}
                      />
                    ) : (
                      <Redirect to="/projects" />
                    );
                  }}
                </WithLoggedInAuth>
              )}
            />

            <SentryRoute
              path="/settings"
              render={() => {
                return (
                  <WithLoggedInAuth>
                    {(_auth, _profile, {currentOrganization}) => {
                      const orgToView = currentOrganization;
                      const redirectParts = [
                        routeUtils.makeUuidPrefix(orgToView.get('id')),
                        location.pathname.slice(1),
                        location.hash || '',
                        location.search || '',
                      ].filter((p) => p);
                      return <Redirect to={`/${redirectParts.join('/')}`} />;
                    }}
                  </WithLoggedInAuth>
                );
              }}
            />

            <SentryRoute
              path="/:orgIdPrefix/settings"
              render={({
                match: {
                  params: {orgIdPrefix},
                },
              }) => {
                return (
                  <routeUtils.CurrentOrgIdPrefix.Provider value={orgIdPrefix}>
                    <WithLoggedInAuth>
                      {(_, profile, {currentOrganization}, loggedInUserActions) => (
                        <>
                          <SentryRoute
                            path="/:orgIdPrefix/settings/:activeView?"
                            render={({history, match}) => {
                              const {activeView = 'organization'} = match.params;
                              return (
                                <>
                                  {isGlobalSettingsView(activeView) ? (
                                    <GlobalSettings
                                      history={history}
                                      organization={currentOrganization}
                                      profile={profile}
                                      loggedInUserActions={loggedInUserActions}
                                      activeView={activeView}
                                    />
                                  ) : (
                                    <Redirect to="/projects" />
                                  )}
                                </>
                              );
                            }}
                          />
                        </>
                      )}
                    </WithLoggedInAuth>
                  </routeUtils.CurrentOrgIdPrefix.Provider>
                );
              }}
            />

            <SentryRoute
              path="/:orgIdPrefix/dashboard"
              render={({
                match: {
                  params: {orgIdPrefix},
                },
              }) => {
                return (
                  <routeUtils.CurrentOrgIdPrefix.Provider value={orgIdPrefix}>
                    <WithLoggedInAuth>
                      {(_, profile, {currentOrganization}, loggedInUserActions) => (
                        <>
                          <SentryRoute
                            path="/:orgIdPrefix/dashboard"
                            render={({history}) => {
                              return (
                                <>
                                  {featureFlags.DASHBOARD(currentOrganization, profile) ? (
                                    <Dashboard
                                      loggedInUserActions={loggedInUserActions}
                                      history={history}
                                    />
                                  ) : (
                                    <Redirect to="/projects" />
                                  )}
                                </>
                              );
                            }}
                          />
                        </>
                      )}
                    </WithLoggedInAuth>
                  </routeUtils.CurrentOrgIdPrefix.Provider>
                );
              }}
            />

            <SentryRoute
              path="/admin/manageProperties/:mode?/:projectIdPrefix?"
              render={({
                match: {
                  params: {mode, projectIdPrefix},
                },
              }) => (
                <WithLoggedInAuth>
                  {(_, __, {currentOrganization}) => {
                    const orgIdPrefix = routeUtils.makeUuidPrefix(currentOrganization.get('id'));
                    const destination = `/${orgIdPrefix}/admin/manageProperties${
                      mode ? `/${mode}` : ''
                    }${projectIdPrefix ? `/${projectIdPrefix}` : ''}`;
                    return <Redirect to={destination} />;
                  }}
                </WithLoggedInAuth>
              )}
            />

            <SentryRoute
              path="/:orgIdPrefix/admin/manageProperties"
              render={({
                history,
                match: {
                  params: {orgIdPrefix},
                },
              }) => (
                <routeUtils.CurrentOrgIdPrefix.Provider value={orgIdPrefix}>
                  <WithLoggedInAuth>
                    {(auth, profile, {currentOrganization}, loggedInUserActions) => (
                      <Switch>
                        <SentryRoute
                          path="/:orgIdPrefix/admin/manageProperties/:mode/:projectId?"
                          render={({
                            match: {
                              params: {projectId, mode},
                            },
                          }) => {
                            //we used to support people just throwing a projectId on the end of the
                            //base URL and getting to the select page for that project. if someone has
                            //something as their first param that is not a mode, try assuming that it's
                            //a projectId and redirect them to the select page for that project. if it's
                            //not a projectId, they'll get a 404, which makes sense because at that point
                            //they'd be passing us a not real URL we haven't supported before.
                            if (!isManagePropertyModeOption(mode)) {
                              return (
                                <Redirect
                                  to={`/${orgIdPrefix}/admin/manageProperties/select/${mode}`}
                                />
                              );
                            } else {
                              return (
                                <RequireRoleContainer
                                  profile={profile}
                                  roles={['owner', 'regular']}
                                >
                                  <ManageFeatures
                                    history={history}
                                    profile={profile}
                                    organization={currentOrganization}
                                    loggedInUserActions={loggedInUserActions}
                                    projectId={projectId}
                                    mode={mode as ManagePropertiesMode}
                                    firebaseToken={auth.firebaseToken}
                                  />
                                </RequireRoleContainer>
                              );
                            }
                          }}
                        />
                        {/* No mode selected - add properties to new portfolio */}
                        <SentryRoute
                          exact
                          render={() => (
                            <ManageFeatures
                              history={history}
                              profile={profile}
                              organization={currentOrganization}
                              loggedInUserActions={loggedInUserActions}
                              mode={'select'}
                              firebaseToken={null}
                            />
                          )}
                        />
                      </Switch>
                    )}
                  </WithLoggedInAuth>
                </routeUtils.CurrentOrgIdPrefix.Provider>
              )}
            />

            <SentryRoute
              path="/admin/updateProperties/:projectIdPrefix"
              render={({
                match: {
                  params: {projectIdPrefix},
                },
              }) => (
                <WithLoggedInAuth>
                  {(_, __, {currentOrganization}) => {
                    const orgIdPrefix = routeUtils.makeUuidPrefix(currentOrganization.get('id'));

                    return (
                      <Redirect
                        to={`/${orgIdPrefix}/admin/updateProperties${
                          projectIdPrefix ? `/${projectIdPrefix}` : ''
                        }`}
                      />
                    );
                  }}
                </WithLoggedInAuth>
              )}
            />

            <SentryRoute
              path="/:orgIdPrefix/admin/updateProperties"
              render={({
                history,
                match: {
                  params: {orgIdPrefix},
                },
              }) => (
                <routeUtils.CurrentOrgIdPrefix.Provider value={orgIdPrefix}>
                  <WithLoggedInAuth>
                    {(_, profile, {currentOrganization}, loggedInUserActions) => (
                      <Switch>
                        <SentryRoute
                          path="/:orgIdPrefix/admin/updateProperties/:projectId"
                          render={({
                            match: {
                              params: {projectId},
                            },
                          }) => (
                            <RequireRoleContainer profile={profile} roles={['owner', 'regular']}>
                              <PropertyUpdate
                                history={history}
                                organization={currentOrganization}
                                profile={profile}
                                loggedInUserActions={loggedInUserActions}
                                projectId={projectId}
                              />
                            </RequireRoleContainer>
                          )}
                        />
                        {/* If there's no project id to capture, redirect to projects. */}
                        <Redirect to="/projects" />
                      </Switch>
                    )}
                  </WithLoggedInAuth>
                </routeUtils.CurrentOrgIdPrefix.Provider>
              )}
            />

            <SentryRoute
              path="/internal-tools"
              render={({history}) => (
                <WithLoggedInAuth>
                  {(auth, profile, {currentOrganization}, loggedInUserActions) =>
                    userUtils.showInternalTools(profile, currentOrganization) ? (
                      <InternalTools
                        history={history}
                        organization={currentOrganization}
                        profile={profile}
                        firebaseIsEmailVerified={auth.firebaseIsEmailVerified}
                        loggedInUserActions={loggedInUserActions}
                      />
                    ) : (
                      <Redirect to="/projects" />
                    )
                  }
                </WithLoggedInAuth>
              )}
            />
            <SentryRoute
              path="/p/:uuid"
              render={({match}) => <PublicMap uuid={match?.params.uuid} />}
            />
            <SentryRoute path="/">
              <Redirect to="/signin" />
            </SentryRoute>
          </Switch>
        </QueryParamProvider>
      </Router>
    );
  }
}
