import * as I from 'immutable';

import {
  ApiAccessSubscription,
  ApiOrganization,
  ApiOrganizationUser,
} from 'app/modules/Remote/Organization';
import * as CONSTANTS from 'app/utils/constants';
import {isUpstreamEmail} from 'app/utils/userUtils';

import {orgIsAllowedTier} from './organizationUtils';

// Reexport useUserInfo to reduce imports for consumers.
export {useUserInfo} from 'app/providers/AuthProvider';
export const UPSTREAM_TEST_ORG_ID = '-KQ66NhS23Am_QKkk7CR';
export const UPSTREAM_DEMO_ORG_ID = 'demo';
export const UPSTREAM_TEST_PARENT_ORG_ID = 'edab39da-c8a0-423a-bb5f-f5bc61d67cbd';
export const UPSTREAM_TEST_CHILD_ORG_ID = 'f92109ba-b63f-4c88-83ec-a73a2b2be097';
export const UPSTREAM_TEST_CANCEL_SUBSCRIPTION_ORG_ID = 'd5867a1d-8cf9-4343-bc25-1ea4afffcaed';

export const FLINT_RIVERKEEPER_ORG_ID = '707fd3b7-7d92-494f-9a66-002b54d27a59';
export const SPNHF_PROPERTY_MONITORING_ORG_ID = '0e4fd0c5-a574-4dee-b775-78ae8d871488';
export const PLANET_LABS_DEMO_ORG_ID = '1078146a-7950-4a53-b847-acc15ec458a4';

// Explicit overrides for specific orgs.
function hasTaskingEstimatorOverride(organization: I.ImmutableOf<ApiOrganization>) {
  return [FLINT_RIVERKEEPER_ORG_ID].includes(organization.get('id'));
}

// Sometimes we want to hardcode access to parcel data for orgs.
//  Add an org id constant above, and then include that constant in the empty array here.
function hasParcelDataInUploaderOverride(organization: I.ImmutableOf<ApiOrganization>) {
  return ([] as string[]).includes(organization.get('id'));
}

/**
 * Returns true if the org is at the given Lens tier or higher. Assumes the
 * tiers are all strictly ranked, so Plus doesn't ever get anything that
 * Enterprise doesn't have.
 */
export function atLeastLensTier(
  targetTier: CONSTANTS.LENS_TIERS_TYPE,
  organization: I.ImmutableOf<ApiOrganization>
) {
  const orgTier = organization.getIn(['tiers', 'Lens']);

  if (!orgTier) {
    return false;
  }

  const orgTierLevel = CONSTANTS.LENS_TIERS.indexOf(orgTier);
  const targetTierLevel = CONSTANTS.LENS_TIERS.indexOf(targetTier);

  return orgTierLevel >= targetTierLevel;
}

const USER_ROLE_LEVELS: ApiOrganizationUser['role'][] = [
  CONSTANTS.USER_ROLE_READONLY,
  CONSTANTS.USER_ROLE_REGULAR,
  CONSTANTS.USER_ROLE_OWNER,
];

/**
 * Returns true if the user is at the given role or higher. Assumes the roles
 * are all strictly ranked, so members don't ever get anything that owners don't
 * have.
 */
function atLeastUserRole(
  targetRole: ApiOrganizationUser['role'],
  profile: I.ImmutableOf<ApiOrganizationUser>
) {
  const role = profile.get('role');
  const roleLevel = USER_ROLE_LEVELS.indexOf(role);
  const targetRoleLevel = USER_ROLE_LEVELS.indexOf(targetRole);
  return roleLevel >= targetRoleLevel;
}

/**
 * Returns true if the user has an Upstream email, and the organization is
 * one of the designated internal organizations.
 */

export function isUpstreamAndInternalOrg(
  organization: I.ImmutableOf<ApiOrganization> | null,
  profile: I.ImmutableOf<ApiOrganizationUser> | null
): boolean {
  // Auth provider may return null for these if we're not logged in. Return false here, conservatively.
  if (!organization || !profile) {
    return false;
  }
  return (
    isUpstreamEmail(profile.get('email')) &&
    [UPSTREAM_TEST_ORG_ID, UPSTREAM_TEST_PARENT_ORG_ID, UPSTREAM_TEST_CHILD_ORG_ID].includes(
      organization.get('id')
    )
  );
}

/**
 * Returns true if the user has an Upstream email, and the organization is
 * one of the designated internal organizations or the demo org.
 */

export function isUpstreamAndInternalOrDemoOrg(
  organization: I.ImmutableOf<ApiOrganization> | null,
  profile: I.ImmutableOf<ApiOrganizationUser> | null
): boolean {
  // Auth provider may return null for these if we're not logged in. Return false here, conservatively.
  if (!organization || !profile) {
    return false;
  }
  return (
    isUpstreamEmail(profile.get('email')) &&
    [
      UPSTREAM_TEST_ORG_ID,
      UPSTREAM_TEST_PARENT_ORG_ID,
      UPSTREAM_TEST_CHILD_ORG_ID,
      UPSTREAM_DEMO_ORG_ID,
    ].includes(organization.get('id'))
  );
}

/**
 * Returns true if the user has an Upstream email, and the assigned user is currently
 * a member of the internal org. This is useful for excluding service accounts that
 * may be attatched to customer orgs but still have @upstream.tech emails.
 */
export function isUpstreamAndMemberOfInternalOrg(
  profile: I.ImmutableOf<ApiOrganizationUser> | null
): boolean {
  // Auth provider may return null for these if we're not logged in. Return false here, conservatively.
  if (!profile) {
    return false;
  }
  return (
    isUpstreamEmail(profile.get('email')) &&
    profile
      .get('organizations')
      .map((userAssociation) => userAssociation?.get('id'))
      .includes(CONSTANTS.INTERNAL_ORGANIZATION_ID)
  );
}

export default {
  LOOKOUTS: (organization: I.ImmutableOf<ApiOrganization>) =>
    atLeastLensTier(CONSTANTS.LENS_TIER_PLUS, organization),

  DASHBOARD: (
    organization: I.ImmutableOf<ApiOrganization>,
    profile: I.ImmutableOf<ApiOrganizationUser>
  ) =>
    atLeastLensTier(CONSTANTS.LENS_TIER_PLUS, organization) &&
    isUpstreamAndInternalOrg(organization, profile),

  ORGANIZATION_LOGO_UPLOAD: (
    organization: I.ImmutableOf<ApiOrganization>,
    profile: I.ImmutableOf<ApiOrganizationUser>
  ) =>
    atLeastLensTier(CONSTANTS.LENS_TIER_PLUS, organization) &&
    profile.get('role') === CONSTANTS.USER_ROLE_OWNER,

  TASK_IMAGERY_MODAL: (
    organization: I.ImmutableOf<ApiOrganization>,
    profile: I.ImmutableOf<ApiOrganizationUser>
  ) =>
    (atLeastLensTier(CONSTANTS.LENS_TIER_PLUS, organization) ||
      hasTaskingEstimatorOverride(organization)) &&
    atLeastUserRole(CONSTANTS.USER_ROLE_REGULAR, profile),

  TASK_IMAGERY_MODAL_SUBMIT: (
    organization: I.ImmutableOf<ApiOrganization>,
    profile: I.ImmutableOf<ApiOrganizationUser>
  ) =>
    (atLeastLensTier(CONSTANTS.LENS_TIER_PLUS, organization) ||
      hasTaskingEstimatorOverride(organization)) &&
    atLeastUserRole(CONSTANTS.USER_ROLE_REGULAR, profile),

  PARCEL_DATA_IN_UPLOADER: (
    organization: I.ImmutableOf<ApiOrganization>,
    _profile: I.ImmutableOf<ApiOrganizationUser>
  ) =>
    orgIsAllowedTier(organization, [CONSTANTS.LENS_TIER_PLUS, CONSTANTS.LENS_TIER_ENTERPRISE]) ||
    hasParcelDataInUploaderOverride(organization),

  //only show subscription cancellation link to org owners in non-enterprise orgs
  SHOW_CANCELLATION_LINK: (
    organization: I.ImmutableOf<ApiOrganization>,
    profile: I.ImmutableOf<ApiOrganizationUser>,
    accessSubscription: ApiAccessSubscription
  ) =>
    atLeastUserRole(CONSTANTS.USER_ROLE_OWNER, profile) &&
    organization.getIn(['tiers', 'Lens']) !== CONSTANTS.LENS_TIER_ENTERPRISE &&
    accessSubscription.productKey !== 'lens-plus-enterprise',

  SMART_SUMMARIES: (organization: I.ImmutableOf<ApiOrganization>) => {
    return orgIsAllowedTier(organization, [
      CONSTANTS.LENS_TIER_PLUS,
      CONSTANTS.LENS_TIER_ENTERPRISE,
    ]);
  },

  CLIPPING_CATEGORY_LAYERS: (organization: I.ImmutableOf<ApiOrganization> | null) =>
    organization && organization.get('id') === CONSTANTS.INTERNAL_ORGANIZATION_ID,
};
