import * as B from '@blueprintjs/core';
import classnames from 'classnames';
import {History} from 'history';
import * as I from 'immutable';
import React from 'react';

import AppNav from 'app/components/AppNav';
import {LensLibraryProviderSettingsModeWrapper} from 'app/components/Library/LensLibraryProvider';
import {OverlaysProvider} from 'app/components/Library/OverlaysProvider';
import OverlaysView from 'app/components/Library/OverlaysView';
import {ApiOrganization, ApiOrganizationUser} from 'app/modules/Remote/Organization';
import {LoggedInUserActions} from 'app/providers/AuthProvider';
import NotificationPrefsProvider from 'app/providers/NotificationPrefsProvider';
import {WithOrgUsers} from 'app/providers/OrgUsersProvider';
import SignupTokenProvider from 'app/providers/SignupTokenProvider';

import BillingView from './BillingView';
import cs from './GlobalSettings.styl';
import NotificationsView from './NotificationsView';
import OrganizationView from './OrganizationView';
import TeamView from './TeamView';
import {NavSidebarContainer} from '../NavSidebar';
import {useNavSidebarContext} from '../NavSidebar/NavSidebarProvider';

const SETTINGS_VIEW_IDS = [
  'organization',
  'team',
  'billing',
  'notifications',
  'overlays',
  'layers',
];
export type ViewId = (typeof SETTINGS_VIEW_IDS)[number];

export const isGlobalSettingsView = (value: string): value is ViewId =>
  SETTINGS_VIEW_IDS.includes(value);

const GlobalSettings: React.FunctionComponent<
  React.PropsWithChildren<{
    history: History;
    organization: I.ImmutableOf<ApiOrganization>;
    profile: I.ImmutableOf<ApiOrganizationUser>;
    loggedInUserActions: LoggedInUserActions;
    activeView: ViewId;
  }>
> = ({history, organization, profile, loggedInUserActions, activeView}) => {
  // On component mount, make sure the settings menu is expanded in the sidebar
  const {setExpandedItems} = useNavSidebarContext();
  React.useEffect(() => {
    setExpandedItems((prev) => [...new Set([...prev, 'settings'])]);
  }, [setExpandedItems]);

  return (
    <div className={cs.page}>
      <AppNav
        history={history}
        organization={organization}
        profile={profile}
        selectedProject={null}
        loggedInUserActions={loggedInUserActions}
      />

      <NavSidebarContainer history={history}>
        <Switch expression={activeView}>
          <Case value="organization">
            <OrganizationView
              organization={organization}
              profile={profile}
              loggedInUserActions={loggedInUserActions}
            />
          </Case>

          <Case value="team">
            <SignupTokenProvider profile={profile} organizationId={organization.get('id')}>
              <TeamView
                organization={organization}
                profile={profile}
                loggedInUserActions={loggedInUserActions}
              />
            </SignupTokenProvider>
          </Case>

          <Case value="billing">
            <WithOrgUsers refreshOnMount="clear">
              {(organizationUsers, _meta, _actions) => (
                <BillingView
                  organization={organization}
                  profile={profile}
                  organizationUsers={organizationUsers}
                />
              )}
            </WithOrgUsers>
          </Case>

          <Case value="notifications">
            <NotificationPrefsProvider>
              <NotificationsView organization={organization} profile={profile} />
            </NotificationPrefsProvider>
          </Case>

          <Case value="overlays" noPadding={true}>
            <OverlaysProvider organization={organization}>
              <OverlaysView organization={organization} history={history} />
            </OverlaysProvider>
          </Case>

          <Case value="layers" noPadding={true}>
            <OverlaysProvider organization={organization}>
              <LensLibraryProviderSettingsModeWrapper
                organization={organization}
                profile={profile}
              />
            </OverlaysProvider>
          </Case>
        </Switch>
      </NavSidebarContainer>
    </div>
  );
};

/**
 * Utility function for implementing a switch-like statement using a React
 * functional component.
 */
const Switch: React.FunctionComponent<
  React.PropsWithChildren<{
    expression: string;
    children: JSX.Element[];
  }>
> = ({expression, children}) => {
  return children.find(({props: {value}}) => value === expression) || <React.Fragment />;
};

/**
 * Utility function for implementing a case condition for a switch-like
 * statement using a React functional component.
 */
const Case: React.FunctionComponent<
  React.PropsWithChildren<{
    value: string;
    children: JSX.Element;
    noPadding?: boolean;
  }>
> = ({noPadding = false, children}) => {
  return (
    // Override default padding for layers library because it interferes with the top
    // toolbar
    <div
      className={classnames(cs.body, {
        [cs.removeBodyPadding]: noPadding,
      })}
    >
      {children}
    </div>
  );
};

/**
 * Spinner that is centered within its parent element.
 */
export const CenteredSpinner: React.FunctionComponent = () => (
  <div className={cs.centeredSpinner}>
    <B.Spinner />
  </div>
);

/**
 * Body component for standard title styling.
 */
export const Body: React.FunctionComponent<
  React.PropsWithChildren<{
    title: string;
    subtitle?: string;
    titleActions?: React.ReactNode;
  }>
> = ({title, subtitle, titleActions, children}) => (
  <React.Fragment>
    <div className={cs.bodyHeader}>
      <div className={cs.bodyHeaderTitle}>
        <h2>{title}</h2>
        {titleActions}
      </div>
      {subtitle && <p>{subtitle}</p>}
    </div>
    {children}
  </React.Fragment>
);

export default GlobalSettings;
